//ICC.1:2022 (Profile version 4.4.0.0)
import ReferenceWhite from "./ReferenceWhite";

const multiplyMatrixByVector = (matrix, vector) => {
    if (matrix[0].length !== vector.length || matrix.length !== 3 || vector.length !== 3) {
        console.error('Invalid matrix or vector dimensions for multiplication');
        return null;
    }
    const result = [
        matrix[0][0] * vector[0] + matrix[0][1] * vector[1] + matrix[0][2] * vector[2],
        matrix[1][0] * vector[0] + matrix[1][1] * vector[1] + matrix[1][2] * vector[2],
        matrix[2][0] * vector[0] + matrix[2][1] * vector[1] + matrix[2][2] * vector[2]
    ];
    return result;
};

const ChromaticAdaptation = (
    Xs, Ys, Zs, sourceWhite, destinationWhite, AdaptationMethod, methodSelect
) => {

    let Xd, Yd, Zd;
    // source white point
    const { Xn: Xws, Yn: Yws, Zn: Zws } = ReferenceWhite(sourceWhite);
    // destination white point
    const { Xn: Xwd, Yn: Ywd, Zn: Zwd } = ReferenceWhite(destinationWhite);



    let nonLinear = false
    if (AdaptationMethod === "newBradfordNR") {
        nonLinear = true;
        AdaptationMethod = "newBradford"
    } else {
        nonLinear = false;
    }


    if (AdaptationMethod === "none") {
        return { Xd: Xs, Yd: Ys, Zd: Zs };
    } else if (AdaptationMethod === "BradfordNonLinear") {
        if (Ys === 0) {
            return { Xd: 0, Yd: 0, Zd: 0 }
        }
        const XYZdivY = [Xs / Ys, Ys / Ys, Zs / Ys];
        const LMSs = multiplyMatrixByVector(BradfordMatrix, XYZdivY);
        const LMSws = multiplyMatrixByVector(BradfordMatrix, [Xws / Yws, Yws / Yws, Zws / Yws]);
        const LMSwd = multiplyMatrixByVector(BradfordMatrix, [Xwd / Ywd, Ywd / Ywd, Zwd / Ywd]);
        const p = Math.pow((LMSws[2] / LMSwd[2]), 0.0834);
        const LMSd = [(LMSwd[0] * LMSs[0] / LMSws[0]), (LMSwd[1] * LMSs[1] / LMSws[1]), (LMSwd[2] * Math.pow((LMSs[2] / LMSws[2]), p))];
        const LMSdMulY = [LMSd[0] * Ys, LMSd[1] * Ys, LMSd[2] * Ys];
        const result = multiplyMatrixByVector(BradfordInverseMatrix, LMSdMulY);

        return { Xd: result[0], Yd: result[1], Zd: result[2] };

    } else if (AdaptationMethod === "ciecat94") {
        const Yb = parseFloat(methodSelect.Yb)
        const D = parseFloat(methodSelect.D)
        let La = parseFloat(methodSelect.La)
        let Lra = 63.66
        const oldVersion = false

        function beta1(inputValue) {
            return (6.469 + 6.362 * Math.pow(inputValue, 0.4495)) / (6.469 + Math.pow(inputValue, 0.4495));
        }

        function beta2(inputValue) {
            return 0.7844 * (8.414 + 8.091 * Math.pow(inputValue, 0.5128)) / (8.414 + Math.pow(inputValue, 0.5128));
        }

        const changeScaleConvention = true;

        let X, Y, Z
        let Xw, Yw, Zw;
        let Xrw, Yrw, Zrw;

        if (changeScaleConvention) {
            X = Xs * 100; Y = Ys * 100; Z = Zs * 100;
            Xw = Xws * 100; Yw = Yws * 100; Zw = Zws * 100;
            Xrw = Xwd * 100; Yrw = Ywd * 100; Zrw = Zwd * 100;
        } else {
            X = Xs; Y = Ys; Z = Zs;
            Xw = Xws; Yw = Yws; Zw = Zws;
            Xrw = Xwd; Yrw = Ywd; Zrw = Zwd;
        }


        // step 1: RGB from XYZ
        const A = VonKriesMatrix;

        const RGB = multiplyMatrixByVector(A, [X, Y, Z])
        const [R, G, B] = RGB;

        const x0w = Xw / (Xw + Yw + Zw);
        const y0w = Yw / (Xw + Yw + Zw);

        const x0rw = Xrw / (Xrw + Yrw + Zrw);
        const y0rw = Yrw / (Xrw + Yrw + Zrw);

        const ksiW = (0.48105 * x0w + 0.78841 * y0w - 0.080811) / y0w;
        const etaW = (-0.27200 * x0w + 1.11962 * y0w + 0.04570) / y0w;
        const zetaW = 0.91822 * (1.0 - x0w - y0w) / y0w;

        const ksiRw = (0.48105 * x0rw + 0.78441 * y0rw - 0.080801) / y0rw;
        const etaRw = (-0.27200 * x0rw + 1.11962 * y0rw + 0.04570) / y0rw;
        const zetaRw = 0.91822 * (1.0 - x0rw - y0rw) / y0rw;

        const RwGwBw = scaleVector(La, [ksiW, etaW, zetaW]);
        const RrwGrwBrw = scaleVector(Lra, [ksiRw, etaRw, zetaRw]);

        const [Rw, Gw, Bw] = RwGwBw;
        const [Rrw, Grw, Brw] = RrwGrwBrw;

        // step 2: Rc, Gc, Bc calculation
        const YYw = Y / Yw;
        let f;
        if (YYw > Math.pow(6 / 29, 3)) {
            f = Math.pow(Y / Yw, 1 / 3);
        } else {
            f = (841 / 108) * (Y / Yw) + 4 / 29;
        }

        const Lstar = 116 * f - 16;
        let alpha;
        if (oldVersion) {
            alpha = 1.0;
        } else {
            alpha = 0.1151 * Math.log10(La) + 0.0025 * (Lstar - 50.0) + (0.22 * D + 0.510);
            if (alpha > 1.0) alpha = 1.0;
        }

        const ksiPrimEtaPrimZetaPrim = subtractVectors([ksiW, etaW, zetaW], scaleVector(1.0 - alpha, [ksiRw, etaRw, zetaRw]));
        const [ksiPrim, etaPrim, zetaPrim] = ksiPrimEtaPrimZetaPrim;

        // noise factor
        const n = oldVersion ? 1.0 : 0.1;

        const num1 = Math.pow((Yb * ksiPrim + n) / (20.0 * ksiPrim + n), (2.0 / 3.0) * beta1(Rrw));
        const den1 = Math.pow((Yb * ksiRw + n) / (20.0 * ksiRw + n), (2.0 / 3.0) * beta1(Rrw));
        const num2 = Math.pow((Yb * etaPrim + n) / (20.0 * etaPrim + n), (1.0 / 3.0) * beta1(Grw));
        const den2 = Math.pow((Yb * etaRw + n) / (20.0 * etaRw + n), (1.0 / 3.0) * beta1(Grw));

        const K = (num1 / den1) * (num2 / den2);

        const Rc = (Yb * ksiRw + n) * Math.pow(K, 1 / beta1(Rrw)) * Math.pow((R + n) / (Yb * ksiPrim + n), beta1(Rw) / beta1(Rrw)) - n;
        const Gc = (Yb * etaRw + n) * Math.pow(K, 1 / beta1(Grw)) * Math.pow((G + n) / (Yb * etaPrim + n), beta1(Gw) / beta1(Grw)) - n;
        const Bc = (Yb * zetaRw + n) * Math.pow(K, 1 / beta2(Brw)) * Math.pow((B + n) / (Yb * zetaPrim + n), beta2(Bw) / beta2(Brw)) - n;

        // step3: Xc, Yc, Zc calculation
        let XcYcZc = multiplyMatrixByVector(VonKriesInverseMatrix, [Rc, Gc, Bc]);
        if (changeScaleConvention) {
            XcYcZc = XcYcZc.map(val => val * 0.01);
        }
        const result = xyzLimiter(XcYcZc);

        return { Xd: result[0], Yd: result[1], Zd: result[2] };

    } else if (AdaptationMethod === "Xcmccat97") {
        const La = parseFloat(methodSelect.La97)
        const F = parseFloat(methodSelect.F)
        const degreeMode = (methodSelect.degreeMode.value)
        const M = BradfordMatrix

        const changeScaleConvention = false;

        let X, Y, Z
        let Xw, Yw, Zw;
        let Xrw, Yrw, Zrw;

        if (changeScaleConvention) {
            X = Xs * 100; Y = Ys * 100; Z = Zs * 100;
            Xw = Xws * 100; Yw = Yws * 100; Zw = Zws * 100;
            Xrw = Xwd * 100; Yrw = Ywd * 100; Zrw = Zwd * 100;
        } else {
            X = Xs; Y = Ys; Z = Zs;
            Xw = Xws; Yw = Yws; Zw = Zws;
            Xrw = Xwd; Yrw = Ywd; Zrw = Zwd;
        }

        const epsilon = 1.0e-10;
        const Y_safe = Math.max(Y, epsilon);

        // Step 1: Convert XYZ to RGB
        const RGB = scaleVector((1.0 / Y), multiplyMatrixByVector(M, [X, Y, Z]));
        const RwGwBw = scaleVector((1.0 / Yw), multiplyMatrixByVector(M, [Xw, Yw, Zw]));
        const RrwGrwBrw = scaleVector((1.0 / Yrw), multiplyMatrixByVector(M, [Xrw, Yrw, Zrw]));

        const [R, G, B] = RGB;
        const [Rw, Gw, Bw] = RwGwBw;
        const [Rrw, Grw, Brw] = RrwGrwBrw;


        // Step 2: Calculate adapting luminance and degree of adaptation
        let D;
        switch (degreeMode) {
            case "zero":
                D = 0.0;
                break;
            case "full":
                D = 1.0;
                break;
            default:
                // "automatic"
                D = F - F / (1.0 + 2.0 * Math.pow(La, 0.25) + Math.pow(La, 2.0) / 300.0);
        }

        // Step 3: cone responses
        const p = Math.pow(Bw / Brw, 0.0834);
        const Rc = (D * (Rrw / Rw) + 1.0 - D) * R;
        const Gc = (D * (Grw / Gw) + 1.0 - D) * G;
        const Bc = (D * (Brw / Math.pow(Bw, p)) + 1.0 - D) * Math.sign(B) * Math.pow(Math.abs(B), p);

        // Step 4: Convert RGB back to XYZ
        let XcYcZc = multiplyMatrixByVector(BradfordInverseMatrix, [Rc * Y_safe, Gc * Y_safe, Bc * Y_safe]);
        if (changeScaleConvention == true) {
            XcYcZc = 0.01 * XcYcZc;
        }
        const result = xyzLimiter(XcYcZc);

        return { Xd: result[0], Yd: result[1], Zd: result[2] };

    } else if (AdaptationMethod === "Xcmccat2000") {
        const La1 = parseFloat(methodSelect.La1_2000)
        const La2 = parseFloat(methodSelect.La2_2000)
        var F = parseFloat(methodSelect.F2000)
        const changeScaleConvention = false;

        // step 1: RGBs from XYZs
        const M = CMCCAT2000Matrix
        const InvM = CMCCAT2000InverseMatrix

        let X, Y, Z
        let Xw, Yw, Zw;
        let Xrw, Yrw, Zrw;

        X = Xs; Y = Ys; Z = Zs;
        Xw = Xws; Yw = Yws; Zw = Zws;
        Xrw = Xwd; Yrw = Ywd; Zrw = Zwd;

        if (changeScaleConvention) {
            X *= 100; Y *= 100; Z *= 100;
            Xw *= 100; Yw *= 100; Zw *= 100;
            Xrw *= 100; Yrw *= 100; Zrw *= 100;
        }

        const epsilon = 1.0e-10;
        if (Y < epsilon) { Y = epsilon }

        const RGB = multiplyMatrixByVector(M, [X, Y, Z]);
        const RwGwBw = multiplyMatrixByVector(M, [Xw, Yw, Zw]);
        const RrwGrwBrw = multiplyMatrixByVector(M, [Xrw, Yrw, Zrw]);

        const [R, G, B] = RGB;
        const [Rw, Gw, Bw] = RwGwBw;
        const [Rrw, Grw, Brw] = RrwGrwBrw;

        // step 2: degree of adaptation
        let D = F * (0.08 * Math.log10((La1 + La2) / 2) + 0.76 - 0.45 * (La1 - La2) / (La1 + La2));

        if (D < 0.0) {
            D = 0.0;
        } else if (D > 1.0) {
            D = 1.0;
        }

        const Rc = (D * (Yw / Yrw) * (Rrw / Rw) + 1.0 - D) * R;
        const Gc = (D * (Yw / Yrw) * (Grw / Gw) + 1.0 - D) * G;
        const Bc = (D * (Yw / Yrw) * (Brw / Bw) + 1.0 - D) * B;

        let XcYcZc = multiplyMatrixByVector(InvM, [Rc, Gc, Bc]);

        if (changeScaleConvention) {
            XcYcZc = XcYcZc.map(x => 0.01 * x);
        }

        const result = xyzLimiter(XcYcZc);

        return { Xd: result[0], Yd: result[1], Zd: result[2] };

    } else if (AdaptationMethod === "Xcat02") {
        const La = parseFloat(methodSelect.La_CAT02)
        var F = parseFloat(methodSelect.F_CAT02)
        const changeScaleConvention = false;

        // step 1: RGBs from XYZs
        const M = CAT02Matrix
        const InvM = CAT02InverseMatrix

        let X, Y, Z
        let Xw, Yw, Zw;
        let Xrw, Yrw, Zrw;

        X = Xs; Y = Ys; Z = Zs;
        Xw = Xws; Yw = Yws; Zw = Zws;
        Xrw = Xwd; Yrw = Ywd; Zrw = Zwd;

        if (changeScaleConvention) {
            X *= 100; Y *= 100; Z *= 100;
            Xw *= 100; Yw *= 100; Zw *= 100;
            Xrw *= 100; Yrw *= 100; Zrw *= 100;
        }

        const epsilon = 1.0e-10;
        if (Y < epsilon) { Y = epsilon }

        const RGB = multiplyMatrixByVector(M, [X, Y, Z]);
        const RwGwBw = multiplyMatrixByVector(M, [Xw, Yw, Zw]);
        const RrwGrwBrw = multiplyMatrixByVector(M, [Xrw, Yrw, Zrw]);

        const [R, G, B] = RGB;
        const [Rw, Gw, Bw] = RwGwBw;
        const [Rrw, Grw, Brw] = RrwGrwBrw;

        // step 2: degree of adaptation
        const D = F * (1.0 - (1.0 / 3.6) * Math.exp((-La - 42.0) / 92.0));

        if (D < 0.0) {
            D = 0.0;
        } else if (D > 1.0) {
            D = 1.0;
        }

        // step 3: cone responses
        const Rc = (D * (Yw / Yrw) * (Rrw / Rw) + 1.0 - D) * R;
        const Gc = (D * (Yw / Yrw) * (Grw / Gw) + 1.0 - D) * G;
        const Bc = (D * (Yw / Yrw) * (Brw / Bw) + 1.0 - D) * B;

        let XcYcZc = multiplyMatrixByVector(InvM, [Rc, Gc, Bc]);

        if (changeScaleConvention) {
            XcYcZc = XcYcZc.map(x => 0.01 * x);
        }

        const result = xyzLimiter(XcYcZc);

        return { Xd: result[0], Yd: result[1], Zd: result[2] };

    } else if (AdaptationMethod === "rlab") {
        // adaptation designed for Xc, Yc, Zc in D65 and 318cd/m2 reference white
        const La = 63.7
        const D = 1.0
        const changeScaleConvention = false;

        const M = [
            [0.3897, -0.6890, -0.0787],
            [-0.2298, 1.1834, 0.0464],
            [0.0000, 0.0000, 1.0000]
        ];

        let X, Y, Z
        let Xw, Yw, Zw;
        let Xrw, Yrw, Zrw;

        X = Xs; Y = Ys; Z = Zs;
        Xw = Xws; Yw = Yws; Zw = Zws;
        Xrw = Xwd; Yrw = Ywd; Zrw = Zwd;

        if (changeScaleConvention) {
            X *= 100; Y *= 100; Z *= 100;
            Xw *= 100; Yw *= 100; Zw *= 100;
            Xrw *= 100; Yrw *= 100; Zrw *= 100;
        }

        const epsilon = 1.0e-10;
        if (Y < epsilon) { Y = epsilon }

        const RGB = multiplyMatrixByVector(M, [X, Y, Z]);
        const RwGwBw = multiplyMatrixByVector(M, [Xw, Yw, Zw]);
        const RrwGrwBrw = multiplyMatrixByVector(M, [Xrw, Yrw, Zrw]);

        const [R, G, B] = RGB;
        const [Rw, Gw, Bw] = RwGwBw;
        const [Rrw, Grw, Brw] = RrwGrwBrw;

        // step 2: coefficients calculation

        const rE = 3.0 * Rw / (Rw + Gw + Bw);
        const gE = 3.0 * Gw / (Rw + Gw + Bw);
        const bE = 3.0 * Bw / (Rw + Gw + Bw);

        const pR = (1.0 + Math.pow(La, 1.0 / 3.0) + rE) / (1.0 + Math.pow(La, 1.0 / 3.0) + 1.0 / rE);
        const pG = (1.0 + Math.pow(La, 1.0 / 3.0) + gE) / (1.0 + Math.pow(La, 1.0 / 3.0) + 1.0 / gE);
        const pB = (1.0 + Math.pow(La, 1.0 / 3.0) + bE) / (1.0 + Math.pow(La, 1.0 / 3.0) + 1.0 / bE);

        const aR = (pR + D * (1.0 - pR)) / Rw;
        const aG = (pG + D * (1.0 - pG)) / Gw;
        const aB = (pB + D * (1.0 - pB)) / Bw;

        const A = [
            [aR, 0, 0],
            [0, aG, 0],
            [0, 0, aB],
        ];
        const matrixR = [
            [1.9569, -1.1882, 0.2313],
            [0.3612, 0.6388, 0.0000],
            [0.0000, 0.0000, 1.0000],
        ];

        const XcYcZc = multiplyMatrixByVector(multiplyMatrices(multiplyMatrices(matrixR, A), M), [X, Y, Z])

        if (changeScaleConvention) {
            XcYcZc = XcYcZc.map(x => 0.01 * x);
        }

        const result = (XcYcZc);

        return { Xd: result[0], Yd: result[1], Zd: result[2] };


    } else if (AdaptationMethod === "wassef") {
        // adaptation designed for adapting from A to C
        // output values not normalized
        let X = Xs; let Y = Ys; let Z = Zs;

        const M = [
            [0.926, 0.441, 0.141],
            [-0.619, 2.341, -0.303],
            [0.553, 0.923, 3.269],
        ];

        const XcYcZc = multiplyMatrixByVector(M, [X, Y, Z])

        const result = (XcYcZc);

        return { Xd: result[0], Yd: result[1], Zd: result[2] };

    } else if (AdaptationMethod === "newBradford") {
        const M = BradfordMatrix

        let X, Y, Z
        let Xw, Yw, Zw;
        let Xrw, Yrw, Zrw;

        X = Xs; Y = Ys; Z = Zs;
        Xw = Xws; Yw = Yws; Zw = Zws;
        Xrw = Xwd; Yrw = Ywd; Zrw = Zwd;



        const changeScaleConvention = false
        if (changeScaleConvention) {
            X *= 100; Y *= 100; Z *= 100;
            Xw *= 100; Yw *= 100; Zw *= 100;
            Xrw *= 100; Yrw *= 100; Zrw *= 100;
        }

        const epsilon = 1.0e-10;
        if (Y < epsilon) { Y = epsilon }

        const RGB = scaleVector((1.0 / Y), multiplyMatrixByVector(M, [X, Y, Z]));
        const RwGwBw = scaleVector((1.0 / Yw), multiplyMatrixByVector(M, [Xw, Yw, Zw]));
        const RrwGrwBrw = scaleVector((1.0 / Yrw), multiplyMatrixByVector(M, [Xrw, Yrw, Zrw]));

        const [R, G, B] = RGB;
        const [Rw, Gw, Bw] = RwGwBw;
        const [Rrw, Grw, Brw] = RrwGrwBrw;

        let p, Rc, Gc, Bc
        if (nonLinear == true) {
            p = Math.pow((Bw / Brw), 0.0834);
            Rc = Rrw / Rw * R;
            Gc = Grw / Gw * G;
            Bc = Brw / Math.pow(Bw, p) * Math.sign(B) * Math.pow(Math.abs(B), p);
        } else {
            Rc = Rrw / Rw * R;
            Gc = Grw / Gw * G;
            Bc = Brw / Bw * B;
        }
        let XcYcZc = multiplyMatrixByVector(BradfordInverseMatrix, [Y * Rc, Y * Gc, Y * Bc]);
        if (changeScaleConvention == true) {
            XcYcZc = 0.01 * XcYcZc;
        }
        const result = xyzLimiter(XcYcZc);

        return { Xd: result[0], Yd: result[1], Zd: result[2] };
    } else {
        // transformation matrix [MA]
        const MA = (methodSelect) => {
            switch (methodSelect) {
                case 'Bradford':
                    return { matrix: BradfordMatrix, inv: BradfordInverseMatrix };
                case 'Von Kries':
                    return { matrix: VonKriesMatrix, inv: VonKriesInverseMatrix };
                case 'XYZ Scaling':
                    return { matrix: XYZScalingMatrix, inv: XYZScalingMatrix };
                case 'CMCCAT97':
                    return { matrix: CMCCAT97Matrix, inv: CMCCAT97InverseMatrix };
                case 'CMCCAT2000':
                    return { matrix: CMCCAT2000Matrix, inv: CMCCAT2000InverseMatrix };
                case 'CAT02':
                    return { matrix: CAT02Matrix, inv: CAT02InverseMatrix };
                default:
                    console.error('Invalid Chromatic Adaptation Method');
                    return null;
            }
        };

        // from XYZ to(ρ, γ, β)
        const coneResponseSource = multiplyMatrixByVector(MA(AdaptationMethod).matrix, ([[Xws], [Yws], [Zws]]))
        const coneResponseDest = multiplyMatrixByVector(MA(AdaptationMethod).matrix, ([[Xwd], [Ywd], [Zwd]]))

        // Scale vector components
        const scalingFactors = [
            [coneResponseDest[0] / coneResponseSource[0], 0, 0],
            [0, coneResponseDest[1] / coneResponseSource[1], 0],
            [0, 0, coneResponseDest[2] / coneResponseSource[2]],
        ]

        // back to XYZ
        const result = multiplyMatrices(MA(AdaptationMethod).inv, multiplyMatrices(scalingFactors, MA(AdaptationMethod).matrix))

        // Output result
        Xd = result[0][0] * Xs + result[0][1] * Ys + result[0][2] * Zs;
        Yd = result[1][0] * Xs + result[1][1] * Ys + result[1][2] * Zs;
        Zd = result[2][0] * Xs + result[2][1] * Ys + result[2][2] * Zs;

        //console.log("XYZ:  ",Xd, Yd, Zd );
        return { Xd, Yd, Zd };
    }
};

const BradfordMatrix = [ // ICC.1:2022 4.4.0.0
    [0.8951000, 0.2664000, -0.1614000],
    [-0.7502000, 1.7135000, 0.0367000],
    [0.0389000, -0.0685000, 1.0296000]
];
const BradfordInverseMatrix = [
    [0.9869929, -0.1470543, 0.1599627],
    [0.4323053, 0.5183603, 0.0492912],
    [-0.0085287, 0.0400428, 0.9684867]
];
const VonKriesMatrix = [
    [0.4002400, 0.7076000, -0.0808100],
    [-0.2263000, 1.1653200, 0.0457000],
    [0.0000000, 0.0000000, 0.9182200],
];
const VonKriesInverseMatrix = [
    [1.8599364, -1.1293816, 0.2198974],
    [0.3611914, 0.6388125, -0.0000064],
    [0.0000000, 0.0000000, 1.0890636]
];
const XYZScalingMatrix = [
    [1, 0, 0],
    [0, 1, 0],
    [0, 0, 1]
];
const CMCCAT97Matrix = [
    [0.895100, -0.750200, 0.038900],
    [0.266400, 1.713500, 0.068500],
    [-0.161400, 0.036700, 1.029600]
];
const CMCCAT97InverseMatrix = [
    [0.977583, 0.429406, -0.065503],
    [-0.158338, 0.514883, -0.028273],
    [0.158890, 0.048961, 0.961990]
];
const CMCCAT2000Matrix = [
    [0.7982, 0.3389, -0.1371],
    [-0.5918, 1.5512, 0.0406],
    [0.0008, 0.0239, 0.9753]
];
const CMCCAT2000InverseMatrix = [
    [1.0765, -0.23766, 0.16121],
    [0.41096, 0.55434, 0.034694],
    [-0.010954, -0.013389, 1.0243]
];
const CAT02Matrix = [
    [0.7328, 0.4296, -0.1624],
    [-0.7036, 1.6975, 0.0061],
    [0.003, 0.0136, 0.9834]
];
const CAT02InverseMatrix = [
    [1.09612382, -0.278869, 0.18274518],
    [0.45436904, 0.47353315, 0.0720978],
    [-0.00962761, -0.00569803, 1.01532564]
];

function multiplyMatrices(matrixA, matrixB) {
    const result = [];
    for (let i = 0; i < 3; i++) {
        result[i] = [];
        for (let j = 0; j < 3; j++) {
            result[i][j] = 0;
            for (let k = 0; k < 3; k++) {
                result[i][j] += matrixA[i][k] * matrixB[k][j];
            }
        }
    }
    return result;
}

function scaleVector(scalar, vector) {
    return vector.map(value => value * scalar);
}

function subtractVectors(vector1, vector2) {
    return vector1.map((value, index) => value - vector2[index]);
}

function xyzLimiter(inXYZ) {
    let outXYZ = inXYZ.slice(0, 3);

    outXYZ = outXYZ.map(value => (typeof value === 'number' && isFinite(value)) ? value : 0.0);
    outXYZ = outXYZ.map(value => value < 0.0 ? 0.0 : value);
    outXYZ = outXYZ.map(value => value < Math.pow(0.1, 7) ? 0.0 : value);

    return outXYZ;
}


export default ChromaticAdaptation;
import React, { useRef, useState } from "react";
import { useTranslation } from 'react-i18next';
import DeltaEResults from "../DeltaEResults";
import RgbResults from "../RgbResults";
import "../../styles/App.css";
import { rgbAlert, deltaEAlert } from "../Alert";
import rgbToHex from "../math/RgbToHex";
import RgbToRgbForm from "../inputForms/RgbtoRgbForm";
import { ColorInfo2 } from "../math/ColorInfo2";
import PlusButton from "../PlusButton";

const RgbtoRgb = () => {

    const { t } = useTranslation();
    const [showLab2, setShowLab2] = useState(false);
    const [buttonVisible, setButtonVisible] = useState(true);
    const handleShowLab2 = () => {
        setShowLab2(true);
        setButtonVisible(false);
    };
    const handleHideLab2 = () => {
        setShowLab2(false);
        setButtonVisible(true);
    };

    const { input: input1, data: rgb1, colorTransformation: colorTransformation1 } = RgbToRgbForm(1, "rgb");
    const { input: input2, data: rgb2, colorTransformation: colorTransformation2 } = RgbToRgbForm(2, "rgb", handleHideLab2);

    const RgbResults1 = RgbResults(rgb1.X, rgb1.Y, rgb1.Z, colorTransformation1);
    const RgbResults2 = RgbResults(rgb2.X, rgb2.Y, rgb2.Z, colorTransformation2);

    const R8bit1 = Math.floor(255 * rgb1.R);
    const G8bit1 = Math.floor(255 * rgb1.G);
    const B8bit1 = Math.floor(255 * rgb1.B);
    const R8bit2 = Math.floor(255 * rgb2.R);
    const G8bit2 = Math.floor(255 * rgb2.G);
    const B8bit2 = Math.floor(255 * rgb2.B);

    const hex1 = rgbToHex(R8bit1, G8bit1, B8bit1);
    const hex2 = rgbToHex(R8bit2, G8bit2, B8bit2);

    const misc1 = ColorInfo2(RgbResults1.data.destinationLab.l, RgbResults1.data.destinationLab.a, RgbResults1.data.destinationLab.b, rgb1.X, rgb1.Y, rgb1.Z, RgbResults1.data.sX, RgbResults1.data.sY, RgbResults1.data.sZ, hex1, colorTransformation1)
    const misc2 = ColorInfo2(RgbResults2.data.destinationLab.l, RgbResults2.data.destinationLab.a, RgbResults2.data.destinationLab.b, rgb2.X, rgb2.Y, rgb2.Z, RgbResults2.data.sX, RgbResults2.data.sY, RgbResults2.data.sZ, hex2, colorTransformation2)

    const data1 = {
        l: RgbResults1.data.destinationLab.l, a: RgbResults1.data.destinationLab.a, b: RgbResults1.data.destinationLab.b,
        R8bit: R8bit1, G8bit: G8bit1, B8bit: B8bit1,
        luvU: misc1.luvU, luvV: misc1.luvV
    };
    const data2 = {
        l: RgbResults2.data.destinationLab.l, a: RgbResults2.data.destinationLab.a, b: RgbResults2.data.destinationLab.b,
        R8bit: R8bit2, G8bit: G8bit2, B8bit: B8bit2,
        luvU: misc2.luvU, luvV: misc2.luvV
    };

    const DeltaEResults1 = DeltaEResults(data1, data2);

    const alertFirstRgb = rgbAlert(rgb1.R, rgb1.G, rgb1.B);
    const alertSecondRgb = rgbAlert(rgb2.R, rgb2.G, rgb2.B);
    const deltaAlert = deltaEAlert(colorTransformation1.sourceColorWhite, colorTransformation2.sourceColorWhite)

    const goTo = (elementRef) => () => {
        if (elementRef && elementRef.current) {
            elementRef.current.scrollIntoView({ behavior: "smooth" });
        }
    };
    const results1Ref = useRef(null);
    const results2Ref = useRef(null);
    const resultsDeltaRef = useRef(null);

    const resultsPreview = (
        <div className="resutlsPreview">
            <div className="resutlsPreview-text-container">
                <div className="resutlsPreview-color-text">
                    {t('color')} 1
                    <br />
                    <b>HEX: {hex1}</b>
                    <br />
                    <button className="goToButton" onClick={goTo(results1Ref)}>{t('goToResults')}</button>
                </div>
                <div className="resutlsPreview-color-text">
                    {t('color')} 2
                    <br />
                    <b>HEX: {hex2}</b>
                    <br />
                    <button className="goToButton" onClick={goTo(results2Ref)}>{t('goToResults')}</button>
                </div>
            </div>
            <div className="resutlsPreview-color-text2">{t('ColorPreview')}</div>
            <div className='resutlsPreview-color-container'>
                <div className='resutlsPreview-color' style={{ backgroundColor: hex1 }} />
                <div className='resutlsPreview-color' style={{ backgroundColor: hex2 }} />
            </div>
            <button className="goToButton" onClick={goTo(resultsDeltaRef)}>{t('checkDiff')}</button>
        </div>
    );


    return (
        <>
            <div className="input-forms-container">
                {input1}
                {showLab2 && resultsPreview}
            </div>
            <div style={{ marginTop: "10px" }}>{alertFirstRgb}</div>
            {buttonVisible && (
                <PlusButton buttonText={t('addRGB')} onClick={handleShowLab2} />
            )}
            {showLab2 && input2}
            <div style={{ marginTop: "10px" }}>{alertSecondRgb}</div>
            <div className="table-title">
                <p className="colorText" ref={results1Ref}>{t('color')} 1 -
                    <b> {t('results')} </b>
                </p>
            </div>
            {RgbResults1.table}
            {misc1.table}
            {showLab2 && <>
                <div className="table-title" >
                    <p className="colorText" ref={results2Ref}>{t('color')} 2 -
                        <b> {t('results')} </b>
                    </p>
                </div>
                {RgbResults2.table}
                {misc2.table}
            </>}
            {showLab2 && (
                <h3 className="title" ref={resultsDeltaRef}>
                    {t('ColorDiff')}
                </h3>
            )}
            {showLab2 && deltaAlert}
            {showLab2 && <p className="delta-note">{t('deltaNoteAfter')}</p>}
            {showLab2 && DeltaEResults1}
        </>
    );
};

export default RgbtoRgb;

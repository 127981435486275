import React from 'react';
import '../styles/Footer.css'
import { useTranslation } from 'react-i18next';

const Autors = () => {
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;

  const AuthorsPL = (<>
    <h2>Autorzy</h2>
    <h3>Zespół PCSS Media Solutions Lab</h3>
    Mariusz Plichta - główny deweloper <br />
    Adam Skorupa - deweloper<br />
    Łukasz Staszak - deweloper <br />
    Magdalena Ryszewska - projektantka UI/UX<br />
    Marcin Dąbrowski - ekspert algorytmów i wartości macierzy<br />
    Piort Surowiec - specjalista kolorymetrii

    <h3>Konsultacje merytoryczne</h3>
    Don Williams - Image Science Associates<br />
    Mateusz Bolesta - Narodowe Archiwum Cyfrowe<br />
    Piotr Kaźmierski - PCSS, Dział Nowych Mediów<br />
    Społeczność projektu DIAG

  </>)

const AuthorsEN = (
  <>

    <h2>Authors</h2>
    <h3>PSNC Media Solutions Lab team </h3>
    Mariusz Plichta - head developer <br />
    Adam Skorupa - developer<br />
    Łukasz Staszak - developer <br />
    Magdalena Ryszewska - UI/UX designer<br />
    Marcin Dąbrowski - algorithms and matrix values expert<br />
    Piotr Surowiec- colorimetry specialist

    <h3>Substantive support</h3>
    Don Williams - Image Science Associates<br />
    Mateusz Bolesta - National Digital Archives<br />
    Piotr Kaźmierski - PSNC, New Media Department<br />
    DIAG project community
  </>

);

return currentLanguage === 'pl' ? AuthorsPL : AuthorsEN
};

export default Autors; 
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import "../../styles/LabForm.css";
import { labFormWarning, wassefWarning, rlabWarning } from "../Alert";
import LabSelectForm from "../SelectForm/LabSelectForm";
import * as SelectOptions from "../SelectForm/SelectOptions";
import deleteIcon from "../../assets/delete-icon.svg"
import resetIcon from "../../assets/reset-icon.svg"
import MethodSelect from "../SelectForm/MethodSelect";

function normalizeInput(input) {
  input = input.replaceAll(/[^0-9.-]/gim, (m) => (m === "," ? "." : ""));
  let dot = input.search(/\./gim);
  if (dot >= 0) {
    input =
      input.substr(0, dot + 1) + input.substr(dot).replaceAll(/\./gim, "");
  }
  if (input.length === 1) {
    input = input.replaceAll(/[^0-9-]/gim, "");
  }
  input = input.slice(0, 1) + input.slice(1).replaceAll("-", "");
  return input;
}

const LabForm = (number, transformTo, onHideLab2) => {
  const { t, i18n } = useTranslation();
  const methodchoice = [
    { value: "Bradford", label: "Bradford, linear, compliant with ICC.1:2022 version 4.4, Annex E" },
    { value: "BradfordNonLinear", label: "Bradford, nonlinear" },
    { value: "Von Kries", label: "Von Kries" },
    { value: "XYZ Scaling", label: "XYZ Scaling" },
    { value: "ciecat94", label: "CIECAT94" },
    { value: "Xcmccat97", label: "CMCCAT97" },
    { value: "Xcmccat2000", label: "CMCCAT2000" },
    { value: "Xcat02", label: "CAT02" },
    { value: "rlab", label: "RLAB" },
    { value: "wassef", label: "Wassef" },
    { value: "none", label: t('none') },
  ];

  const [whitepoint, setWhitepoint] = useState(SelectOptions.whitepoint[0]);
  const [conversionToColorSpace, setConversionToColorSpace] = useState(SelectOptions.conversionToColorSpace[0]);
  const [method, setMethod] = useState(methodchoice[0]);
  const [destinationWhite, setDestinationWhite] = useState();
  const [l, setL] = useState(0);
  const [a, setA] = useState(0);
  const [b, setB] = useState(0);
  const handleClearValues = () => {
    setL(0);
    setA(0);
    setB(0);
  };

  //If source white i destination white are the same, change method to "none" 
  useEffect(() => {
    if (conversionToColorSpace.label.includes(whitepoint.label)) {
      setMethod(methodchoice.find((method) => method.value === "none"));
    } else {
      setMethod(methodchoice.find((method) => method.value === "Bradford"));
    }
  }, [conversionToColorSpace, whitepoint]);


  useEffect(() => {
    if (conversionToColorSpace && conversionToColorSpace.whitepoint) {
      const correspondingWhitepoint = SelectOptions.whitepoint.find((wp) => wp.value === conversionToColorSpace.whitepoint);
      if (correspondingWhitepoint) {
        setDestinationWhite(correspondingWhitepoint);
      }
    }
  }, [conversionToColorSpace]);

  const handleDeleteInput = () => {
    handleClearValues();
    onHideLab2();
  };

  const [showRlabWarning, setShowRlabWarning] = useState(false);
  const [showWassefWarning, setShowWassefWarning] = useState(false);
  useEffect(() => {
    if (method.value === "rlab") {
      setShowRlabWarning(true);
      setShowWassefWarning(false);
    } else if (method.value === "wassef") {
      setShowRlabWarning(false);
      setShowWassefWarning(true);
    } else {
      setShowRlabWarning(false);
      setShowWassefWarning(false);
    }
  }, [method]);

  const methodSelect = MethodSelect(method)

  const converterText = (
    <p className="colorText">
      {t('color')} {number} -
      <p className="switch-value"> L*a*b* </p>
      {t('to')}
      <p className="switch-value"> {transformTo === "rgb" ? "RGB" : "NCS"} </p>
    </p>
  );


  const input = (
    <div className="input">
      <div className="color">
        {converterText}
        {number === 2 && (<button
          type="button"
          onClick={handleDeleteInput}
          className="switch-button-delete"
        >
          <img src={deleteIcon} className="button-icon" alt="button" />
          {t('delete')}
        </button>)}
      </div>

      <form>
        <div className="lab-form-input-container">
          <label>
            L*:
            <input
              type="text"
              value={l}
              onChange={(e) => setL(normalizeInput(e.target.value))}
              onFocus={(e) => e.target.select()}
              onBlur={(e) => {
                if (e.target.value.trim() === "") {
                  setL("0");
                }
              }}
              className="lab-input"
            />
          </label>
          <label>
            a*:
            <input
              type="text"
              value={a}
              onChange={(e) => setA(normalizeInput(e.target.value))}
              onFocus={(e) => e.target.select()}
              onBlur={(e) => {
                if (e.target.value.trim() === "") {
                  setA("0");
                }
              }}
              className="lab-input"
            />
          </label>
          <label>
            b*
            <input
              type="text"
              value={b}
              onChange={(e) => setB(normalizeInput(e.target.value))}
              onFocus={(e) => e.target.select()}
              onBlur={(e) => {
                if (e.target.value.trim() === "") {
                  setB("0");
                }
              }}
              className="lab-input"
            />
          </label>
          <button
            type="button"
            onClick={handleClearValues}
            className="clear-button"
          >
            <img src={resetIcon} className="button-icon" alt="button" />
            {t("clear")}
          </button>
        </div>

        <div className="lab-form-select-container">
          <div className="lab-form-select-container">
            <label>
              {t("whitepoint")}
              <LabSelectForm
                value={whitepoint}
                onChange={(selectedOption) => setWhitepoint(selectedOption)}
                options={SelectOptions.whitepoint}
              />
            </label>
            <label>
              {t("method")}
              <LabSelectForm
                value={method}
                onChange={(selectedOption) => { setMethod(selectedOption) }}
                options={methodchoice}
              />
            </label>
            {methodSelect.jsx()}
            {showWassefWarning && wassefWarning()}
            {showRlabWarning && rlabWarning()}
            <label>
              {t("space")}
              <LabSelectForm
                value={conversionToColorSpace}
                onChange={(selectedOption) => setConversionToColorSpace(selectedOption)}
                options={SelectOptions.conversionToColorSpace}
              />
            </label>
          </div>
        </div>
      </form>
      {labFormWarning(l, a, b)}
    </div>
  );

  return {
    input,
    data: { l: parseFloat(l), a: parseFloat(a), b: parseFloat(b) },
    colorTransformation: {
      adaptationMethod: method.value,
      sourceColorWhite: whitepoint.value,
      destinationColorSpace: conversionToColorSpace.value,
      destinationColorWhite: conversionToColorSpace.whitepoint,
      methodSelect: methodSelect.misc,
    },
  };
};

export default LabForm;
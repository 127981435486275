/* 
 Nazewnictwo zmiennych:
 Δ nazywane jest d     i dodawane jest na początku zmiennej
 ′ nazywane jest p     i dodawane jest na koncu zmiennej
 ¯ nazywane jest s     i dodawane jest na końcu zmiennej, przed "p"
 */
function T(b1, b2, a1p, a2p) {
  return 1 - 0.17 * Math.cos(hPrime(b1, b2, a1p, a2p) - Math.PI / 6) + 0.24 * Math.cos(2 * hPrime(b1, b2, a1p, a2p)) + 0.32 * Math.cos(3 * hPrime(b1, b2, a1p, a2p) + Math.PI / 30) - 0.20 * Math.cos(4 * hPrime(b1, b2, a1p, a2p) - 63 * Math.PI / 180);
}

function hPrime(b1, b2, a1p, a2p) {
  const h1p = Math.atan2(b1, a1p) >= 0 ? Math.atan2(b1, a1p) : Math.atan2(b1, a1p) + 2 * Math.PI;
  const h2p = Math.atan2(b2, a2p) >= 0 ? Math.atan2(b2, a2p) : Math.atan2(b2, a2p) + 2 * Math.PI;

  if (Math.abs(h1p - h2p) > Math.PI) return (h1p + h2p + 2 * Math.PI) / 2;
  else return (h1p + h2p) / 2;
}


const CalculatorDeltaE = (lab1, lab2) => {
  let l1 = parseFloat(lab1.l);
  let a1 = parseFloat(lab1.a);
  let b1 = parseFloat(lab1.b);
  let u1 = parseFloat(lab1.luvU);
  let v1 = parseFloat(lab1.luvV);
  let l2 = parseFloat(lab2.l);
  let a2 = parseFloat(lab2.a);
  let b2 = parseFloat(lab2.b);  
  let u2 = parseFloat(lab2.luvU);
  let v2 = parseFloat(lab2.luvV);


  //Delta E 1994, ASTM D2244−15
  const calculateDeltaE94 = (l1, l2, a1, a2, b1, b2, kL, k1, k2) => {
    const C1 = Math.sqrt(Math.pow(a1, 2) + Math.pow(b1, 2));
    const C2 = Math.sqrt(Math.pow(a2, 2) + Math.pow(b2, 2));
    const dC = C1 - C2;
    const dH = (Math.pow((a1 - a2), 2) + Math.pow((b1 - b2), 2) - Math.pow(dC, 2));// no Math.sqrt as we take it to Math.pow later
    const kc = 1;
    const kh = 1;
    const Sl = 1;
    const SC = 1 + k1 * C1;
    const SH = 1 + k2 * C1;
    return Math.sqrt(
      Math.pow((l1 - l2) / (kL * Sl), 2) +
      Math.pow(dC / (kc * SC), 2) +
      (dH / Math.pow((kh * SH), 2)) //dH fix - no Math.pow
    )
  };

  //Delta E CMC, ASTM D2244−15
  const calculateCMC = (l1, l2, a1, a2, b1, b2) => {
    const C1 = Math.sqrt(Math.pow(a1, 2) + Math.pow(b1, 2));
    const C2 = Math.sqrt(Math.pow(a2, 2) + Math.pow(b2, 2));
    const dC = C1 - C2;
    const dH = (Math.pow((a1 - a2), 2) + Math.pow((b1 - b2), 2) - Math.pow(dC, 2)); // no Math.sqrt as we take it to Math.pow later
    let SLcmc; if (l1 < 16) { SLcmc = 0.511; } else { SLcmc = (0.040975 * l1) / (1 + 0.01765 * l1) };
    const SCcmc = 0.638 + ((0.0638 * C1) / (1 + (0.0131 * C1)))
    const H = (Math.atan2(b1, b2) * 180) / Math.PI;
    const H1 = H >= 0 ? H : H + 360;
    let Tcmc; if (164 <= H1 && H1 <= 345) { Tcmc = 0.56 + Math.abs(0.2 * Math.cos(H1 + 168 * Math.PI / 180)); } else { Tcmc = 0.36 + Math.abs(0.4 * Math.cos(H1 + 35 * Math.PI / 180)); };
    const F = Math.sqrt(Math.pow(C1, 4) / (Math.pow(C1, 4) + 1900))
    const SHcmc = SCcmc * (F * Tcmc + 1 - F)
    return Math.sqrt(
      Math.pow(((l1 - l2) / (SLcmc)), 2) +
      Math.pow((dC / (SCcmc)), 2) +
      (dH / Math.pow(SHcmc, 2)) // dH fix - no Math.pow
    );
  };

  //DeltaE2000, ISO/CIE-11664-6
  function CalculateDeltaE2000(l1, l2, a1, a2, b1, b2, kL) {
    const kC = 1;
    const kH = 1;
    const lp = (l1 + l2) / 2;
    const cp1 = Math.sqrt(Math.pow(a1, 2) + Math.pow(b1, 2));
    const cp2 = Math.sqrt(Math.pow(a2, 2) + Math.pow(b2, 2));
    const cp = (cp1 + cp2) / 2;
    const g = 0.5 * (1 - Math.sqrt(Math.pow(cp, 7) / (Math.pow(cp, 7) + Math.pow(25, 7))));
    const a1p = a1 * (1 + g);
    const a2p = a2 * (1 + g);
    const c1p = Math.sqrt(Math.pow(a1p, 2) + Math.pow(b1, 2));
    const c2p = Math.sqrt(Math.pow(a2p, 2) + Math.pow(b2, 2));
    const cp_ = (c1p + c2p) / 2;
    let h1p = Math.atan2(b1, a1p) >= 0 ? Math.atan2(b1, a1p) : Math.atan2(b1, a1p) + 2 * Math.PI;
    let h2p = Math.atan2(b2, a2p) >= 0 ? Math.atan2(b2, a2p) : Math.atan2(b2, a2p) + 2 * Math.PI;
    const dlP = l2 - l1;
    const dcP = c2p - c1p;
    const dhPrime = (() => {
      if (c1p * c2p === 0) return 0;
      else if (Math.abs(h2p - h1p) <= Math.PI) return h2p - h1p;
      else if (h2p - h1p > Math.PI) return h2p - h1p - 2 * Math.PI;
      else return h2p - h1p + 2 * Math.PI;
    })();
    const dhp = 2 * Math.sqrt(c1p * c2p) * Math.sin(dhPrime / 2);
    const sl = 1 + (0.015 * Math.pow(lp - 50, 2)) / Math.sqrt(20 + Math.pow(lp - 50, 2));
    const sc = 1 + 0.045 * cp_;
    const sh = 1 + 0.015 * cp_ * T(b1, b2, a1p, a2p);
    const rt = -Math.sin(2 * dhPrime) * (2 * Math.sqrt(Math.pow(cp_, 7) / (Math.pow(cp_, 7) + Math.pow(25, 7))));
    return Math.sqrt(Math.pow(dlP / (kL * sl), 2) + Math.pow(dcP / (kC * sc), 2) + Math.pow(dhp / (kH * sh), 2) + rt * (dcP / (kC * sc)) * (dhp / (kH * sh)));
  };

  const calculateDeltaEDin99 = (l1, l2, a1, a2, b1, b2, Ke, Kch) => {
    // Ke = 2 if textiles else 1  Kch = 0.5 if textiles else 1
    const L991 = (1 / Ke) * 105.51 * Math.log(1 + 0.0158 * l1);
    const L992 = (1 / Ke) * 105.51 * Math.log(1 + 0.0158 * l2);
    const e1 = a1 * Math.cos(16 * (Math.PI / 180)) + b1 * Math.sin(16 * (Math.PI / 180));
    const e2 = a2 * Math.cos(16 * (Math.PI / 180)) + b2 * Math.sin(16 * (Math.PI / 180));
    const f1 = -0.7 * a1 * Math.sin(16 * (Math.PI / 180)) + b1 * Math.cos(16 * (Math.PI / 180));
    const f2 = -0.7 * a2 * Math.sin(16 * (Math.PI / 180)) + b2 * Math.cos(16 * (Math.PI / 180));
    const G1 = Math.pow(e1 ** 2 + f1 ** 2, 0.5);
    const G2 = Math.pow(e2 ** 2 + f2 ** 2, 0.5);

    let h_ef1;
    let h_ef2;

    if (e1 > 0 && f1 >= 0) {
      h_ef1 = Math.atan(f1 / e1);
    } else if (e1 === 0 && f1 > 0) {
      h_ef1 = Math.PI / 2;
    } else if (e1 < 0) {
      h_ef1 = Math.PI + Math.atan(f1 / e1);
    } else if (e1 === 0 && f1 < 0) {
      h_ef1 = (3 * Math.PI) / 2;
    } else if (e1 > 0 && f1 <= 0) {
      h_ef1 = 2 * Math.PI + Math.atan(f1 / e1);
    } else if (e1 === 0 && f1 === 0) {
      h_ef1 = 0;
    }

    if (e2 > 0 && f2 >= 0) {
      h_ef2 = Math.atan(f2 / e2);
    } else if (e2 === 0 && f2 > 0) {
      h_ef2 = Math.PI / 2;
    } else if (e2 < 0) {
      h_ef2 = Math.PI + Math.atan(f2 / e2);
    } else if (e2 === 0 && f2 < 0) {
      h_ef2 = (3 * Math.PI) / 2;
    } else if (e2 > 0 && f2 <= 0) {
      h_ef2 = 2 * Math.PI + Math.atan(f2 / e2);
    } else if (e2 === 0 && f2 === 0) {
      h_ef2 = 0;
    }

    const h991 = h_ef1 * (180 / Math.PI);
    const h992 = h_ef2 * (180 / Math.PI);
    const c991 = (Math.log(1 + 0.045 * G1)) / (0.045 * Kch * Ke);
    const c992 = (Math.log(1 + 0.045 * G2)) / (0.045 * Kch * Ke);
    const a991 = c991 * Math.cos(h_ef1);
    const a992 = c992 * Math.cos(h_ef2);
    const b991 = c991 * Math.sin(h_ef1);
    const b992 = c992 * Math.sin(h_ef2);

    return Math.sqrt(Math.pow(L991 - L992, 2) + Math.pow(a991 - a992, 2) + Math.pow(b991 - b992, 2));
  };

  // deafult values or graphic arts
  let kL = 1; let k1 = 0.045; let k2 = 0.015;
  let Ke = 1; let Kch = 1;

  const DeltaE76 = Math.sqrt(Math.pow(l2 - l1, 2) + Math.pow(a2 - a1, 2) + Math.pow(b2 - b1, 2)).toFixed(6); //DeltaE1976, ISO 11664-4
  const DeltaCIELUV = Math.sqrt(Math.pow(l2 - l1, 2) + Math.pow(u2 - u1, 2) + Math.pow(v2 - v1, 2)).toFixed(6);
  const CMC = calculateCMC(l1, l2, a1, a2, b1, b2).toFixed(6);
  const DeltaE94 = calculateDeltaE94(l1, l2, a1, a2, b1, b2, kL, k1, k2).toFixed(6);
  const DeltaE00 = CalculateDeltaE2000(l1, l2, a1, a2, b1, b2, kL).toFixed(6);
  const DeltaEDin99 = calculateDeltaEDin99(l1, l2, a1, a2, b1, b2, Ke, Kch).toFixed(6);

  // values change for textiles 
  kL = 2; k1 = 0.048; k2 = 0.014;
  Ke = 2; Kch = 0.5;
  // Delta-E for textiles
  const DeltaE94Textile = calculateDeltaE94(l1, l2, a1, a2, b1, b2, kL, k1, k2).toFixed(6);
  const DeltaE00Tex = CalculateDeltaE2000(l1, l2, a1, a2, b1, b2, kL).toFixed(6);
  const DeltaEDin99Tex = calculateDeltaEDin99(l1, l2, a1, a2, b1, b2, Ke, Kch).toFixed(6);
  return { DeltaE76, DeltaCIELUV, DeltaE94, DeltaE94Textile, CMC, DeltaE00, DeltaE00Tex, DeltaEDin99, DeltaEDin99Tex };
};

export default CalculatorDeltaE;

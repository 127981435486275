import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import "../../styles/LabForm.css";
import LabSelectForm from "../SelectForm/LabSelectForm";
import * as SelectOptions from "../SelectForm/SelectOptions";
import deleteIcon from "../../assets/delete-icon.svg";
import MethodSelect from "../SelectForm/MethodSelect";
import { ncs2xyz } from "../math/NcsFogra";
import NcsTable from "../math/NcsTable.json";
import iconFiles from "../../assets/Files.svg";

const NcsForm = (number, transformTo, onHideLab2) => {
    const { t } = useTranslation();
    const methodchoice = [
        { value: "Bradford", label: "Bradford, linear, compliant with ICC.1:2022 version 4.4, Annex E" },
        { value: "BradfordNonLinear", label: "Bradford, nonlinear" },
        { value: "Von Kries", label: "Von Kries" },
        { value: "XYZ Scaling", label: "XYZ Scaling" },
        { value: "ciecat94", label: "CIECAT94" },
        { value: "Xcmccat97", label: "CMCCAT97" },
        { value: "Xcmccat2000", label: "CMCCAT2000" },
        { value: "Xcat02", label: "CAT02" },
        { value: "rlab", label: "RLAB" },
        { value: "wassef", label: "Wassef" },
        { value: "none", label: t('none') },
    ];

    const ncsOptions = NcsTable.map(row => ({
        value: row[1],
        label: row[1]
    }));

    const [selectedFormat, setSelectedFormat] = useState(format[1]);
    const [method, setMethod] = useState(methodchoice[0]);
    const [sourceSpace, setSourceSpace] = useState(SelectOptions.conversionToColorSpace[0]);
    const [whitepoint, setWhitepoint] = useState(SelectOptions.whitepoint[0]);
    const [Ncs, setNcs] = useState(ncsOptions[0].value);

    const handleClearValues = () => {
        setNcs(ncsOptions[0].value);
    };

    useEffect(() => {
        if (sourceSpace.label.includes(whitepoint.label)) {
            setMethod(methodchoice.find((method) => method.value === "none"));
        } else {
            setMethod(methodchoice.find((method) => method.value === "Bradford"));
        }
    }, [sourceSpace, whitepoint]);

    const handleNcsChange = (selectedOption) => {
        setNcs(selectedOption.value);
    };

    const handleDeleteInput = () => {
        handleClearValues();
        onHideLab2();
    };

    const handleNext = () => {
        const currentIndex = ncsOptions.findIndex(option => option.value === Ncs);
        const nextIndex = (currentIndex + 1) % ncsOptions.length;
        setNcs(ncsOptions[nextIndex].value);
    };

    const handlePrevious = () => {
        const currentIndex = ncsOptions.findIndex(option => option.value === Ncs);
        const previousIndex = currentIndex === 0 ? ncsOptions.length - 1 : currentIndex - 1;
        setNcs(ncsOptions[previousIndex].value);
    };

    const [showRlabWarning, setShowRlabWarning] = useState(false);
    const [showWassefWarning, setShowWassefWarning] = useState(false);

    useEffect(() => {
        if (method.value === "rlab") {
            setShowRlabWarning(true);
            setShowWassefWarning(false);
        } else if (method.value === "wassef") {
            setShowRlabWarning(false);
            setShowWassefWarning(true);
        } else {
            setShowRlabWarning(false);
            setShowWassefWarning(false);
        }
    }, [method]);

    const methodSelect = MethodSelect(method);

    const XYZ = ncs2xyz(Ncs);

    const handleCopyNCS = () => {
        navigator.clipboard.writeText(Ncs);
    };

    const input = (
        <div className="input">
            <div className="color">
                <p className="colorText">
                    {t('color')} {number} -
                    <p className="switch-value"> NCS </p>
                    {t('to')}
                    <p className="switch-value"> {transformTo === "rgb" ? "RGB" : "L*a*b*"} </p>
                </p>
                {number === 2 && (
                    <button
                        type="button"
                        onClick={handleDeleteInput}
                        className="switch-button-delete"
                    >
                        <img src={deleteIcon} className="button-icon" alt="button" />
                        {t('delete')}
                    </button>
                )}
            </div>
            <div>illuminant D50, observer CIE 1931, 2° </div>

            <form>
                <div className="lab-form-input-container">
                    <div className="lab-form-select-container">
                        <label>
                            NCS:
                            <div className="flex items-center gap-2">

                                <div style={{display:"flex", justifyContent:"center", gap:10}}>
                                    <LabSelectForm
                                        value={ncsOptions.find(option => option.value === Ncs)}
                                        onChange={handleNcsChange}
                                        options={ncsOptions}
                                        isSearchable={true}
                                    />
                                </div>
                                <div style={{ display: "flex", justifyContent: "space-between", minWidth: 150 }}>
                                    <button
                                        type="button"
                                        onClick={handlePrevious}
                                        className="px-3 py-1"
                                    >
                                        {t('previous')}
                                    </button>
                                    <button
                                        type="button"
                                        onClick={handleNext}
                                        className="px-3 py-1"
                                    >
                                        {t('next')}
                                    </button>
                                </div>
                            </div>
                                    <img src={iconFiles} alt="copy"
                                        style={{
                                            marginTop: "-15px",
                                            width: "18px",
                                            opacity: "0.4",
                                            verticalAlign: "middle",
                                        }}
                                        onClick={handleCopyNCS} />
                        </label>
                    </div>
                </div>
            </form>
        </div>
    );

    return {
        input,
        data: { Ncs, X: XYZ[0] / 100, Y: XYZ[1] / 100, Z: XYZ[2] / 100 },
        colorTransformation: {
            adaptationMethod: "Bradford",
            sourceColorWhite: "D50 1931",
            destinationColorWhite: "D65 1931",
            destinationColorSpace: 'sRGB',
            methodSelect: methodSelect.misc,
        },
    };
};

export default NcsForm;

const format = [
    { value: "01", label: "0-1 range" },
    { value: "8-bit", label: "8-bit" },
    { value: "10-bit", label: "10-bit" },
    { value: "15+1-bit", label: "15+1-bit" },
    { value: "16-bit", label: "16-bit" },
    { value: "32-bit", label: "32-bit" },
];
import React, { useState } from "react";
import { useTranslation } from 'react-i18next';
import DeltaEResults from "../DeltaEResults";
import XyztoLab from "../math/XyzToLab";
import "../../styles/App.css";
import { rgbAlert, deltaEAlert } from "../Alert";
import RgbToHex from "../math/RgbToHex";
import ChromaticAdaptation from "../math/ChromaticAdaptation";
import { ColorInfo2 } from "../math/ColorInfo2";
import NcsForm from "../inputForms/ncsForm";
import { cmyk2lab, ncs2cmyk } from "../math/NcsFogra";
import PlusButton from "../PlusButton";
import XyzToRgb from "../math/XyzToRgb";
import LabToXyz from "../math/LabToXyz";

const NcsToLab = () => {
    const { t } = useTranslation();
    const [showLab2, setShowLab2] = useState(false);
    const [buttonVisible, setButtonVisible] = useState(true);

    const handleShowLab2 = () => {
        setShowLab2(true);
        setButtonVisible(false);
    };
    const handleHideLab2 = () => {
        setShowLab2(false);
        setButtonVisible(true);
    };

    const { input: input1, data: rgb1, colorTransformation: colorTransformation1 } = NcsForm(1, "lab");
    const { input: input2, data: rgb2, colorTransformation: colorTransformation2 } = NcsForm(2, "lab", handleHideLab2);

    const Cmyk1 = ncs2cmyk(rgb1.Ncs)
    const NcsLab1 = cmyk2lab(Cmyk1)
    const NcsXyz1 = LabToXyz(NcsLab1[0], NcsLab1[1], NcsLab1[2], "D50 1931")
    const NcsRgb1 = XyzToRgb(NcsXyz1.X, NcsXyz1.Y, NcsXyz1.Z, colorTransformation1)
    const hex1 = RgbToHex(NcsRgb1.R8bit, NcsRgb1.G8bit, NcsRgb1.B8bit);

    const Cmyk2 = ncs2cmyk(rgb2.Ncs)
    const NcsLab2 = cmyk2lab(Cmyk2)
    const NcsXyz2 = LabToXyz(NcsLab2[0], NcsLab2[1], NcsLab2[2], "D50 1931")
    const NcsRgb2 = XyzToRgb(NcsXyz2.X, NcsXyz2.Y, NcsXyz2.Z, colorTransformation2)
    const hex2 = RgbToHex(NcsRgb2.R8bit, NcsRgb2.G8bit, NcsRgb2.B8bit);

    const NcsInfo1 = (
        <>
            <div className="hex">
                Fogra39L CMYK: {Cmyk1[0]} {Cmyk1[1]} {Cmyk1[2]} {Cmyk1[3]}
            </div>
        </>
    )

    const NcsInfo2 = (
        <>
            <div className="hex">
                Fogra39L CMYK: {Cmyk2[0]} {Cmyk2[1]} {Cmyk2[2]} {Cmyk2[3]}
            </div>
        </>
    )

    const adapted1 = ChromaticAdaptation(NcsXyz1[0], NcsXyz1[1], NcsXyz1[2], colorTransformation1.sourceColorWhite, colorTransformation1.destinationColorWhite, colorTransformation1.adaptationMethod, colorTransformation1.methodSelect)
    const adapted2 = ChromaticAdaptation(NcsXyz2[0], NcsXyz2[1], NcsXyz2[2], colorTransformation2.sourceColorWhite, colorTransformation2.destinationColorWhite, colorTransformation2.adaptationMethod, colorTransformation2.methodSelect)

    const destLab1 = XyztoLab(adapted1.Xd, adapted1.Yd, adapted1.Zd, colorTransformation1.destinationColorWhite)
    const destLab2 = XyztoLab(adapted2.Xd, adapted2.Yd, adapted2.Zd, colorTransformation2.destinationColorWhite)

    const misc1 = ColorInfo2(destLab1.l, destLab1.a, destLab1.b, adapted1.Xd, adapted1.Yd, adapted1.Zd, rgb1.X, rgb1.Y, rgb1.Z, hex1, colorTransformation1)
    const misc2 = ColorInfo2(destLab2.l, destLab2.a, destLab2.b, adapted2.Xd, adapted2.Yd, adapted2.Zd, rgb2.X, rgb2.Y, rgb2.Z, hex2, colorTransformation2)

    const data1 = {
        l: NcsLab1[0], a: NcsLab1[1], b: NcsLab1[2],
        R8bit: NcsRgb1.R8bit, G8bit: NcsRgb1.G8bit, B8bit: NcsRgb1.B8bit,
        luvU: misc1.luvU, luvV: misc1.luvV
    };
    const data2 = {
        l: NcsLab2[0], a: NcsLab2[1], b: NcsLab2[2],
        R8bit: NcsRgb2.R8bit, G8bit: NcsRgb2.G8bit, B8bit: NcsRgb2.B8bit,
        luvU: misc2.luvU, luvV: misc2.luvV
    };

    const DeltaEResults1 = DeltaEResults(data1, data2);

    const alertFirstRgb = rgbAlert(rgb1.R, rgb1.G, rgb1.B);
    const alertSecondRgb = rgbAlert(rgb2.R, rgb2.G, rgb2.B);
    const deltaAlert = deltaEAlert(colorTransformation1.sourceColorWhite, colorTransformation2.sourceColorWhite)

    return (
        <>
            {input1}
            <div style={{ marginTop: "10px" }}>{alertFirstRgb}</div>
            {buttonVisible && (
                <PlusButton buttonText={t('addNCS')} onClick={handleShowLab2} />
            )}
            {showLab2 && input2}
            <div style={{ marginTop: "10px" }}>{alertSecondRgb}</div>
            <div className="table-title">
                <p className="colorText" >{t('color')} 1 -
                    <b> {t('results')} </b>
                </p>
                <span className="title-result">L*= {parseFloat(NcsLab1[0]).toFixed(2)}, a*= {Number(NcsLab1[1]).toFixed(2)}, b*= {Number(NcsLab1[2]).toFixed(2)}</span>
            </div>

            <div className="preview-text">{t("ColorPreview")}</div>
            <div style={{ backgroundColor: hex1, maxWidth: 750, height: 70, marginBottom: 25 }} />
            <div className="preview-text" style={{ marginTop: -20 }}>{t("ColorPreviewSrgb")}</div>

            {NcsInfo1}
            {showLab2 && <>
                <div className="table-title" >
                    <p className="colorText" >{t('color')} 2 -
                        <b> {t('results')} </b>
                    </p>
                    <span className="title-result">L*= {parseFloat(NcsLab2[0]).toFixed(2)}, a*= {Number(NcsLab2[1]).toFixed(2)}, b*= {Number(NcsLab2[2]).toFixed(2)}</span>
                </div>
                <div className="preview-text">{t("ColorPreview")}</div>
                <div style={{ backgroundColor: hex2, maxWidth: 750, height: 70, marginBottom: 25 }} />
                <div className="preview-text" style={{ marginTop: -20 }}>{t("ColorPreviewSrgb")}</div>

                {NcsInfo2}
            </>}
            {showLab2 && (
                <h3 className="title" >
                    {t('ColorDiff')}
                </h3>
            )}
            {showLab2 && deltaAlert}
            {showLab2 && <p className="delta-note">{t('deltaNoteAfter')}</p>}
            {showLab2 && DeltaEResults1}
        </>
    );
};

export default NcsToLab;

import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import "../../styles/LabForm.css";
import LabSelectForm from "../SelectForm/LabSelectForm";
import { wassefWarning, rlabWarning } from "../Alert";
import * as SelectOptions from "../SelectForm/SelectOptions";
import deleteIcon from "../../assets/delete-icon.svg";
import resetIcon from "../../assets/reset-icon.svg";
import RGBtoXYZ from "../math/RGBtoXyz";
import MethodSelect from "../SelectForm/MethodSelect";

function normalizeInputDigits(input) {
    return input.replaceAll(/[^0-9.-]/gim, (m) => (m === "," ? "." : ""));
}

function applyFormatLimits(input, selectedFormat, sourceSpace) {
    input = input.replaceAll(/[^0-9.-]/gim, (m) => (m === "," ? "." : ""));
    let dot = input.search(/\./gim);
    if (dot >= 0) {
        input = input.substr(0, dot + 1) + input.substr(dot).replaceAll(/\./gim, "");
    }
    if (input.length === 1) {
        input = input.replaceAll(/[^0-9-]/gim, "");
    }
    input = input.slice(0, 1) + input.slice(1).replaceAll("-", "");

    let formatLimits;
    if (sourceSpace === "Rec709normative") {
        formatLimits = {
            "01": { min: 0, max: 1 },
            "8-bit": { min: 16, max: 235 },
            "10-bit": { min: 64, max: 940 },
            "15+1-bit": { min: 2048, max: 30080 },
            "16-bit": { min: 4096, max: 60160 },
            "32-bit": { min: 268435456, max: 3942645760 }
        }
    } else {
        formatLimits = {
            "01": { min: 0, max: 1 },
            "8-bit": { min: 0, max: 255 },
            "10-bit": { min: 0, max: 1023 },
            "15+1-bit": { min: 0, max: 32767 },
            "16-bit": { min: 0, max: 65535 },
            "32-bit": { min: 0, max: 4294967295 }
        };
    }

    const format = formatLimits[selectedFormat.value];
    if (format) {
        const value = parseFloat(input);
        if (isNaN(value) || value < format.min) {
            return format.min.toString();
        } else if (value > format.max) {
            return format.max.toString();
        }
    }

    return input;
}

const RgbToRgbForm = (number, transformTo, onHideLab2) => {
    const { t } = useTranslation();
    const methodchoice = [
        { value: "Bradford", label: "Bradford, linear, compliant with ICC.1:2022 version 4.4, Annex E" },
        { value: "BradfordNonLinear", label: "Bradford, nonlinear" },
        { value: "Von Kries", label: "Von Kries" },
        { value: "XYZ Scaling", label: "XYZ Scaling" },
        { value: "ciecat94", label: "CIECAT94" },
        { value: "Xcmccat97", label: "CMCCAT97" },
        { value: "Xcmccat2000", label: "CMCCAT2000" },
        { value: "Xcat02", label: "CAT02" },
        { value: "rlab", label: "RLAB" },
        { value: "wassef", label: "Wassef" },
        { value: "none", label: t('none') },
    ];

    const [selectedFormat, setSelectedFormat] = useState(format[1]);
    const [method, setMethod] = useState(methodchoice[0]);
    const [sourceSpace, setSourceSpace] = useState(SelectOptions.conversionToColorSpace[0]);
    const [destinationSpace, setDestinationSpace] = useState(SelectOptions.conversionToColorSpace[3]);

    const [r, setR] = useState("0");
    const [g, setG] = useState("0");
    const [b, setB] = useState("0");
    const handleClearValues = () => {
        setR("0");
        setG("0");
        setB("0");
    };
    useEffect(() => {
        if (sourceSpace.whitepoint.includes(destinationSpace.whitepoint)) {
            setMethod(methodchoice.find((method) => method.value === "none"));
        } else {
            setMethod(methodchoice.find((method) => method.value === "Bradford"));
        }
    }, [sourceSpace, destinationSpace]);

    const handleInputChange = (inputHandler) => (setValue) => (e) => {
        const inputValue = inputHandler(e.target.value);
        setValue(inputValue);
    };

    const handleInputBlur = (inputHandler) => (setValue) => (e) => {
        const inputValue = e.target.value.trim() === "" ? "0" : e.target.value;
        const normalizedValue = inputHandler(inputValue, selectedFormat, sourceSpace.value);
        setValue(normalizedValue);
    };

    const handleDeleteInput = () => {
        handleClearValues();
        onHideLab2();
    };

    const [showRlabWarning, setShowRlabWarning] = useState(false);
    const [showWassefWarning, setShowWassefWarning] = useState(false);
    useEffect(() => {
        if (method.value === "rlab") {
            setShowRlabWarning(true);
            setShowWassefWarning(false);
        } else if (method.value === "wassef") {
            setShowRlabWarning(false);
            setShowWassefWarning(true);
        } else {
            setShowRlabWarning(false);
            setShowWassefWarning(false);
        }
    }, [method]);

    const methodSelect = MethodSelect(method)

    let R = parseFloat(r);
    let G = parseFloat(g);
    let B = parseFloat(b);
    let x, y;

    switch (selectedFormat.value) {
        case '01':
            break;
        case '8-bit':
            x = (sourceSpace.value === "Rec709normative") ? 16 : 0;
            y = (sourceSpace.value === "Rec709normative") ? 235 : 255;
            break;
        case '10-bit':
            x = (sourceSpace.value === "Rec709normative") ? 64 : 0;
            y = (sourceSpace.value === "Rec709normative") ? 940 : 1023;
            break;
        case '15+1-bit':
            x = (sourceSpace.value === "Rec709normative") ? 2048 : 0;
            y = (sourceSpace.value === "Rec709normative") ? 30080 : 32767;
            break;
        case '16-bit':
            x = (sourceSpace.value === "Rec709normative") ? 4096 : 0;
            y = (sourceSpace.value === "Rec709normative") ? 60160 : 65535;
            break;
        case '32-bit':
            x = (sourceSpace.value === "Rec709normative") ? 268435456 : 0;
            y = (sourceSpace.value === "Rec709normative") ? 3942645760 : 4294967295;
            break;
        default:
            console.error('Unsupported color format');
            break;
    }

    if (selectedFormat.value !== '01') {
        R = (R - x) / (y - x);
        G = (G - x) / (y - x);
        B = (B - x) / (y - x);
    }

    const RgbFix = RGBtoXYZ(R, G, B, sourceSpace, selectedFormat);

    const rgbSelect = (
        <div className="lab-form-select-container">
            <label>
                Format:
                <LabSelectForm
                    value={selectedFormat}
                    onChange={(selectedOption) => setSelectedFormat(selectedOption)}
                    options={format}
                />
            </label>
            <label>
                {t('inputSpace')}
                <LabSelectForm
                    value={sourceSpace}
                    onChange={setSourceSpace}
                    options={SelectOptions.conversionToColorSpace}
                />
            </label>
        </div>
    );


    const input = (
        <div className="input">
            <div className="color">
                <p className="colorText">
                    {t('color')} {number} -
                    <p className="switch-value"> RGB </p>
                    {t('to')}
                    <p className="switch-value"> {transformTo === "rgb" ? "RGB" : "L*a*b*"} </p>
                </p>
                {number === 2 && (<button
                    type="button"
                    onClick={handleDeleteInput}
                    className="switch-button-delete"
                >
                    <img src={deleteIcon} className="button-icon" alt="button" />
                    {t('delete')}
                </button>)}
            </div>
            {rgbSelect}

            <form>
                <div className="lab-form-input-container">
                    <label>
                        R:
                        <input
                            type="text"
                            value={r}
                            onChange={handleInputChange(normalizeInputDigits)(setR)}
                            onFocus={(e) => e.target.select()}
                            onBlur={handleInputBlur(applyFormatLimits)(setR)}
                            className="lab-input"
                        />
                    </label>
                    <label>
                        G:
                        <input
                            type="text"
                            value={g}
                            onChange={handleInputChange(normalizeInputDigits)(setG)}
                            onFocus={(e) => e.target.select()}
                            onBlur={handleInputBlur(applyFormatLimits)(setG)}
                            className="lab-input"
                        />
                    </label>
                    <label>
                        B:
                        <input
                            type="text"
                            value={b}
                            onChange={handleInputChange(normalizeInputDigits)(setB)}
                            onFocus={(e) => e.target.select()}
                            onBlur={handleInputBlur(applyFormatLimits)(setB)}
                            className="lab-input"
                        />
                    </label>
                    <button
                        type="button"
                        onClick={handleClearValues}
                        className="clear-button"
                    >
                        <img src={resetIcon} className="button-icon" alt="button" />
                        {t("clear")}
                    </button>
                </div>

                <div className="lab-form-select-container">
                    <div className="lab-form-select-container">
                        <label>
                            {t("method")}
                            <LabSelectForm
                                value={method}
                                onChange={(selectedOption) => { setMethod(selectedOption) }}
                                options={methodchoice}
                            />
                        </label>
                        {methodSelect.jsx()}
                        {showWassefWarning && wassefWarning()}
                        {showRlabWarning && rlabWarning()}
                        <label>
                            {t("outputSpace")}
                            <LabSelectForm
                                value={destinationSpace}
                                onChange={setDestinationSpace}
                                options={SelectOptions.conversionToColorSpace}
                            />
                        </label>
                    </div>
                </div>
            </form>


        </div>
    );

    return {
        input,
        data: { r, g, b, X: RgbFix.X, Y: RgbFix.Y, Z: RgbFix.Z, R, G, B },
        colorTransformation: {
            adaptationMethod: method.value,
            sourceColorWhite: sourceSpace.whitepoint,
            destinationColorSpace: destinationSpace.value,
            destinationColorWhite: destinationSpace.whitepoint,
            methodSelect: methodSelect.misc,
        },
        convertToTheSameSpace: (destinationSpace.value === sourceSpace.value)
    };
};

export default RgbToRgbForm;

const format = [
    { value: "01", label: "0-1 range" },
    { value: "8-bit", label: "8-bit" },
    { value: "10-bit", label: "10-bit" },
    { value: "15+1-bit", label: "15+1-bit" },
    { value: "16-bit", label: "16-bit" },
    { value: "32-bit", label: "32-bit" },
];

import ReferenceWhite from "./ReferenceWhite";

const LuvToXyz = (l, u, v, sourceWhite) => {
    const { Xn, Yn, Zn } = ReferenceWhite(sourceWhite);
    const epsilon = Math.pow(6 / 29, 3);

    const u0 = 4 * Xn / (Xn + 15 * Yn + 3 * Zn)
    const v0 = 9 * Yn / (Xn + 15 * Yn + 3 * Zn)

    const up = u / (13 * l) + u0
    const vp = v / (13 * l) + v0
    var yp = (l + 16) / 116


    const Y = (Math.pow(yp, 3) > epsilon) ? Math.pow(yp, 3) : (yp - 16 / 116) / 7.787
    const X = -(9 * Y * up) / ((up - 4) * vp - up * vp)
    const Z = (9 * Y - (15 * vp * Y) - (vp * X)) / (3 * vp)

    return { X, Y, Z };
};

export default LuvToXyz;

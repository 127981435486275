import React, { useState } from "react";
import { useTranslation } from 'react-i18next';
import DeltaEResults from "../DeltaEResults";
import "../../styles/App.css";
import { rgbAlert, deltaEAlert } from "../Alert";
import ChromaticAdaptation from "../math/ChromaticAdaptation";
import { ColorInfo2 } from "../math/ColorInfo2";
import PlusButton from "../PlusButton";
import CmykForm from "../inputForms/CmykForm";
import LabToXyz from "../math/LabToXyz";
import RgbResults from "../RgbResults";

const CmykToLab = () => {
    const { t } = useTranslation();
    const [showLab2, setShowLab2] = useState(false);
    const [buttonVisible, setButtonVisible] = useState(true);

    const handleShowLab2 = () => {
        setShowLab2(true);
        setButtonVisible(false);
    };
    const handleHideLab2 = () => {
        setShowLab2(false);
        setButtonVisible(true);
    };

    const { input: input1, data: rgb1, colorTransformation: colorTransformation1 } = CmykForm(1, "lab");
    const { input: input2, data: rgb2, colorTransformation: colorTransformation2 } = CmykForm(2, "lab", handleHideLab2);
    const lab1 = rgb1.lab
    const lab2 = rgb2.lab

    const xyz1 = LabToXyz(lab1.l, lab1.a, lab1.b, colorTransformation1.sourceColorWhite)
    const xyz2 = LabToXyz(lab2.l, lab2.a, lab2.b, colorTransformation2.sourceColorWhite)

    const adapted1 = ChromaticAdaptation(xyz1.X, xyz1.Y, xyz1.Z, colorTransformation1.sourceColorWhite, colorTransformation1.destinationColorWhite, colorTransformation1.adaptationMethod, colorTransformation1.methodSelect)
    const adapted2 = ChromaticAdaptation(xyz2.X, xyz2.Y, xyz2.Z, colorTransformation2.sourceColorWhite, colorTransformation2.destinationColorWhite, colorTransformation2.adaptationMethod, colorTransformation2.methodSelect)


    const RgbResults1 = RgbResults(xyz1.X, xyz1.Y, xyz1.Z, colorTransformation1);
    const RgbResults2 = RgbResults(xyz2.X, xyz2.Y, xyz2.Z, colorTransformation2);


    const R8bit1 = RgbResults1.data.R8bit
    const G8bit1 = RgbResults1.data.G8bit
    const B8bit1 = RgbResults1.data.B8bit
    const R8bit2 = RgbResults1.data.R8bit
    const G8bit2 = RgbResults1.data.G8bit
    const B8bit2 = RgbResults1.data.B8bit

    const hex1 = (RgbResults1.data.hex);
    const hex2 = (RgbResults2.data.hex);

    const misc1 = ColorInfo2(lab1.l, lab1.a, lab1.b, adapted1.Xd, adapted1.Yd, adapted1.Zd, xyz1.X, xyz1.Y, xyz1.Z, hex1, colorTransformation1)
    const misc2 = ColorInfo2(lab2.l, lab2.a, lab2.b, adapted2.Xd, adapted2.Yd, adapted2.Zd, xyz2.X, xyz2.Y, xyz2.Z, hex2, colorTransformation2)

    const data1 = {
        l: lab1.l, a: lab1.a, b: lab1.b,
        R8bit: R8bit1, G8bit: G8bit1, B8bit: B8bit1,
        luvU: misc1.luvU, luvV: misc1.luvV
    };
    const data2 = {
        l: lab2.l, a: lab2.a, b: lab2.b,
        R8bit: R8bit2, G8bit: G8bit2, B8bit: B8bit2,
        luvU: misc2.luvU, luvV: misc2.luvV
    };

    const DeltaEResults1 = DeltaEResults(data1, data2);
    const deltaAlert = deltaEAlert(colorTransformation1.sourceColorWhite, colorTransformation2.sourceColorWhite)

    return (
        <>
            {input1}
            {buttonVisible && (
                <PlusButton buttonText={t('addCMYK')} onClick={handleShowLab2} />
            )}
            {showLab2 && input2}
            <div className="table-title">
                <p className="colorText" >{t('color')} 1 -
                    <b> {t('results')} </b>
                </p>
                <span className="title-result">L*= {parseFloat(lab1.l).toFixed(2)}, a*= {Number(lab1.a).toFixed(2)}, b*= {Number(lab1.b).toFixed(2)}</span>
            </div>
            <div className="preview-text">{t("ColorPreview")}</div>
            <div style={{ backgroundColor: hex1, maxWidth: 750, height: 70, marginBottom: 25 }} />
            <div className="preview-text" style={{ marginTop: -20 }}>{t("ColorPreviewSrgb")}</div>
            <div className="hex">
                HEX: {hex1}
            </div>

            {showLab2 && <>
                <div className="table-title" >
                    <p className="colorText" >{t('color')} 2 -
                        <b> {t('results')} </b>
                    </p>
                    <span className="title-result">L*= {Number(lab2.l).toFixed(2)}, a*= {Number(lab2.a).toFixed(2)}, b*= {Number(lab2.b).toFixed(2)}</span>
                </div>
                <div className="preview-text">{t("ColorPreview")}</div>
                <div style={{ backgroundColor: hex2, maxWidth: 750, height: 70, marginBottom: 25 }} />
                <div className="preview-text" style={{ marginTop: -20 }}>{t("ColorPreviewSrgb")}</div>
                <div className="hex">
                    HEX: {hex2}
                </div>

            </>}
            {showLab2 && (
                <h3 className="title" >
                    {t('ColorDiff')}
                </h3>
            )}
            {showLab2 && deltaAlert}
            {showLab2 && <p className="delta-note">{t('deltaNoteAfter')}</p>}
            {showLab2 && DeltaEResults1}
        </>
    );
};

export default CmykToLab;

import React, { useRef, useState } from "react";
import { useTranslation } from 'react-i18next';
import DeltaEResults from "../DeltaEResults";
import RgbResults from "../RgbResults";
import "../../styles/App.css";
import { rgbAlert, deltaEAlert } from "../Alert";
import LabToXyz from "../math/LabToXyz";
import { ColorInfo } from "../math/ColorInfo";
import { lab2cmyk, lab2ncs } from "../math/NcsFogra";
import RgbToHex from "../math/RgbToHex";
import PlusButton from "../PlusButton";
import LabToNcsForm from "../inputForms/LabToNcsForm";

const LabToCmyk = () => {
    const { t } = useTranslation();
    const [showLab2, setShowLab2] = useState(false);
    const [buttonVisible, setButtonVisible] = useState(true);

    const handleShowLab2 = () => {
        setShowLab2(true);
        setButtonVisible(false);
    };

    const handleHideLab2 = () => {
        setShowLab2(false);
        setButtonVisible(true);
    };
    const { input: input1, data: lab1, colorTransformation: colorTransformation1 } = LabToNcsForm(1, "CMYK");
    const { input: input2, data: lab2, colorTransformation: colorTransformation2 } = LabToNcsForm(2, "CMYK", handleHideLab2);

    const Xyz1 = LabToXyz(lab1.l, lab1.a, lab1.b, colorTransformation1.sourceColorWhite)
    const Xyz2 = LabToXyz(lab2.l, lab2.a, lab2.b, colorTransformation2.sourceColorWhite)
    const RgbResults1 = RgbResults(Xyz1.X, Xyz1.Y, Xyz1.Z, colorTransformation1);
    const RgbResults2 = RgbResults(Xyz2.X, Xyz2.Y, Xyz2.Z, colorTransformation2);

    const Ncs1 = lab2ncs([lab1.l, lab1.a, lab1.b])
    const Ncs2 = lab2ncs([lab2.l, lab2.a, lab2.b])
    const cmyk1 = lab2cmyk([lab1.l, lab1.a, lab1.b])
    const cmyk2 = lab2cmyk([lab2.l, lab2.a, lab2.b])
    const hex1 = RgbToHex(RgbResults1.data.R8bit, RgbResults1.data.G8bit, RgbResults1.data.B8bit)
    const hex2 = RgbToHex(RgbResults2.data.R8bit, RgbResults2.data.G8bit, RgbResults2.data.B8bit)

    const NcsInfo = (
        <>
            <div className="hex" >
                HEX: {hex1}
            </div>
        </>
    )

    const NcsInfo2 = (
        <>
            <div className="hex" >
                HEX: {hex2}
            </div>
        </>
    )

    const deltaAlert = deltaEAlert(colorTransformation1.sourceColorWhite, colorTransformation2.sourceColorWhite)
    const alertFirstRgb = rgbAlert(RgbResults1.rgbAlertData.ExtendedR, RgbResults1.rgbAlertData.ExtendedG, RgbResults1.rgbAlertData.ExtendedB);
    const alertSecondRgb = rgbAlert(RgbResults2.rgbAlertData.ExtendedR, RgbResults2.rgbAlertData.ExtendedG, RgbResults2.rgbAlertData.ExtendedB);

    const misc1 = ColorInfo(
        lab1.l, lab1.a, lab1.b,
        Xyz1.X, Xyz1.Y, Xyz1.Z,
        RgbResults1.data.sX, RgbResults1.data.sY, RgbResults1.data.sZ,
        RgbResults1.data.hex, colorTransformation1, RgbResults1.data.destinationLab
    )

    const misc2 = ColorInfo(
        lab2.l, lab2.a, lab2.b,
        Xyz2.X, Xyz2.Y, Xyz2.Z,
        RgbResults2.data.sX, RgbResults2.data.sY, RgbResults2.data.sZ,
        RgbResults2.data.hex, colorTransformation1, RgbResults2.data.destinationLab
    )

    const data1 = {
        l: lab1.l, a: lab1.a, b: lab1.b,
        R8bit: RgbResults1.data.R8bit, G8bit: RgbResults1.data.G8bit, B8bit: RgbResults1.data.B8bit,
        luvU: misc1.luvU, luvV: misc1.luvV
    };
    const data2 = {
        l: lab2.l, a: lab2.a, b: lab2.b,
        R8bit: RgbResults2.data.R8bit, G8bit: RgbResults2.data.G8bit, B8bit: RgbResults2.data.B8bit,
        luvU: misc2.luvU, luvV: misc2.luvV
    };

    const DeltaEResults1 = DeltaEResults(data1, data2);

    return (
        <>
            {input1}
            <div style={{ marginTop: "10px" }}>{alertFirstRgb}</div>
            {buttonVisible && (
                <PlusButton buttonText={t('addLAB')} onClick={handleShowLab2} />
            )}
            {showLab2 && input2}
            <div style={{ marginTop: "10px" }}>{alertSecondRgb}</div>
            <div className="table-title">
                <p className="colorText" >{t('color')} 1 -
                    <b> {t('results')} </b>
                </p>
                <span className="title-result">CMYK = {cmyk1[0]} {cmyk1[1]} {cmyk1[2]} {cmyk1[3]}</span>
            </div>
            <div className="preview-text">{t("ColorPreview")}</div>
            <div style={{ backgroundColor: hex1, maxWidth: 750, height: 70, marginBottom: 25 }} />
            <div className="preview-text" style={{ marginTop: -20 }}>{t("ColorPreviewSrgb")}</div>
            {NcsInfo}

            {showLab2 &&
                <div className="table-title">
                    <p className="colorText" >{t('color')} 2 -
                        <b> {t('results')} </b>
                    </p>
                    <span className="title-result">CMYK = {cmyk2[0]} {cmyk2[1]} {cmyk2[2]} {cmyk2[3]}</span>
                </div>}
            {showLab2 && <>
                <div className="preview-text">{t("ColorPreview")}</div>
                <div style={{ backgroundColor: hex2, maxWidth: 750, height: 70, marginBottom: 25 }} />
                <div className="preview-text" style={{ marginTop: -20 }}>{t("ColorPreviewSrgb")}</div>
            </>
            }
            {showLab2 && NcsInfo2}
            {showLab2 && (
                <h3 className="title">
                    {t('ColorDiff')}
                </h3>
            )}
            {showLab2 && deltaAlert}
            {showLab2 && <p className="delta-note">{t('deltaNoteBefore')}</p>}
            {showLab2 && DeltaEResults1}
        </>
    );
};

export default LabToCmyk;

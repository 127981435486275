import React from 'react';
import '../styles/Header.css';
import logo_diag from '../assets/DIAGRAM_logo.svg'
import logo_diag_dark from '../assets/DIAGRAM_logo_dark.svg'
import Language from "./Language/Language";
import { useTranslation } from 'react-i18next';
import { useNavigate } from "react-router-dom";
import ThemeToggle from './ThemeToggle';
import { useTheme } from './ThemeContext';

const MainMenu = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const currentLanguage = i18n.language;

  const handleRefreshClick = () => {
    const urlPath = currentLanguage === 'pl' ? '/konwerter' : '/converter';
    navigate(urlPath);
  };

  return (
    <div className="mainMenu">
      {/* <button className='mainMenu-button'>{t('KnowledgeBase')}</button> */}
      <a className='mainMenu-button' href='/' >DIAG</a>
      <div style={{ width: '0.5px', height: '14px' }} className='mainMenu-space' />
      <button className='mainMenu-button1' onClick={handleRefreshClick}>{t('converter')} </button>
    </div>
  );
};

const Header = () => {
  const { t } = useTranslation();
  const { theme } = useTheme();
  return (
    <>
      <header className="header">
        <img src={logo_diag} alt="Logo_diag"  className={`diaglogo ${theme === 'dark' ? 'hidden' : ''}`} />
        <img src={logo_diag_dark} alt="Logo_diag" className={`diaglogo ${theme === 'light' ? 'hidden' : ''}`} />
        <div>
          <p className='shultz'>{t("shultz1")}<br />
            <span style={{ marginLeft: "20px" }}>{t("shultz2")}</span>
            <br />
            <br />
            <span style={{fontStyle:"normal"}}>{t("shultz3")} </span>
          </p>
        </div>
          <div  className="header-button">
          <Language />
          <ThemeToggle />
          </div>
      </header>
      <MainMenu />
      <h1 className="color-converter">{t('colorConverter')}</h1>
    </>
  );
};

export default Header;

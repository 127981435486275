import React from 'react';
import '../styles/Footer.css';
import { useTranslation } from 'react-i18next';
import { useNavigate } from "react-router-dom";
import { useTheme } from './ThemeContext';

import PCSSLogo from '../assets/PCSS_logo.svg';
import PCSSLogoDark from '../assets/PCSS_logo_dark.svg';
import PSNCLogo from '../assets/PSNC_logo.svg';
import PSNCLogoDark from '../assets/PSNC_logo_dark.svg';

const Footer = () => {
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const navigate = useNavigate();
  const { theme } = useTheme();
  
  const redirectToPCSS = () => {
    window.location.href = t('PSNClink');
  };

  const logoSrc = theme === 'dark'
    ? (currentLanguage === 'pl' ? PCSSLogoDark : PSNCLogoDark)
    : (currentLanguage === 'pl' ? PCSSLogo : PSNCLogo);

  const redirectToAuthors = () => {
    const urlPath = currentLanguage === 'pl' ? '/konwerter/autorzy' : '/converter/authors';
    navigate(urlPath);
  };

  return (
    <footer className="footer">
      <div className="footer-left" onClick={redirectToPCSS} title={t('PSNClink')}>
        <img 
          src={logoSrc} 
          alt="Logo" 
          className={`logofooter `} 
        />
        <p>{t('Production')} <strong>{t('PSNC')}</strong>, Media Solutions Lab</p>
      </div>
      <div className="footer-right">
        {/* <a>{t('regulations')}</a>
        <a> {t('contact')}</a>
        <a>{t('policy')}</a> */}
        <a onClick={redirectToAuthors}>{t('authors')}</a>
      </div>
    </footer>
  );
};

export default Footer;

import React from "react";
import { useTranslation } from 'react-i18next';
import PCSS_Logo from "./assets/PCSS_logo.svg";
import PCSS_Logo_dark from "./assets/PCSS_logo_dark.svg";
import "./welcome-page.css";
import { useTheme } from "../ThemeContext";

function Copyright() {
  const { t } = useTranslation();
  const { theme } = useTheme();
  const psncButton = t("PSNClink");

  return (
    <div className="copyright-container">
      <a className="copyright-button" href={psncButton}>
        <img
          src={PCSS_Logo}
          alt="PSNC Logo"
          className={`copyright-logo ${theme === 'dark' ? 'hidden' : ''}`}
        />
        <img
          src={PCSS_Logo_dark}
          alt="PSNC Logo"
          className={`copyright-logo ${theme === 'light' ? 'hidden' : ''}`}
        />
        <div className="copyright-text">
          {t("PSNC")}
          <br />
          {t("ICHB")}
        </div>
      </a>
      <a className="footer-msl">Media Solutions Lab</a>
    </div>
  );
}

export default Copyright;

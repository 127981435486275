import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import '../../styles/Language.css'
import { useNavigate } from "react-router-dom";

const Language = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  const handleLanguageChange = (language) => {
    i18n.changeLanguage(language);
    localStorage.setItem('selectedLanguage', language);

    const newPath = language === 'pl' ? '/konwerter' : '/converter';
    navigate(newPath);
  };

  return (
    <div className="lang">
      <button
        className={`lang-button ${i18n.language === 'pl' ? 'active' : ''}`}
        onClick={() => handleLanguageChange('pl')}>
        PL
      </button>
      <button
        className={`lang-button ${i18n.language === 'en' ? 'active' : ''}`}
        onClick={() => handleLanguageChange('en')}>
        EN
      </button>
    </div>
  );
};

export default Language;

import React from "react";
import PropTypes from "prop-types";
import plusCircle from "../assets/Plus-circle.svg";
import plusCircleDark from "../assets/Plus-circle_dark.svg";
import "../styles/App.css";
import { useTheme } from "./ThemeContext";

const PlusButton = ({ buttonText, onClick }) => {
    const { theme } = useTheme();
    return (
        <button className="addLabButton" onClick={onClick}>
            <img src={plusCircle} alt="plus-circle-icon" className={`plusCircle-icon ${theme === 'dark' ? 'hidden' : ''}`} />
            <img src={plusCircleDark} alt="plus-circle-icon" className={`plusCircle-icon ${theme === 'light' ? 'hidden' : ''}`}/>
            {buttonText}
        </button>
    );
};

PlusButton.propTypes = {
    buttonText: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
};

export default PlusButton;

import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import "../../styles/LabForm.css";
import { wassefWarning, rlabWarning } from "../Alert";
import * as SelectOptions from "../SelectForm/SelectOptions";
import deleteIcon from "../../assets/delete-icon.svg";
import resetIcon from "../../assets/reset-icon.svg";
import MethodSelect from "../SelectForm/MethodSelect";
import { cmyk2lab, cmyk2xyz } from "../math/NcsFogra";


function normalizeInput(input) {
    input = input.replaceAll(/[^0-9.-]/gim, (m) => (m === "," ? "." : ""));
    let dot = input.search(/\./gim);
    if (dot >= 0) {
        input =
            input.substr(0, dot + 1) + input.substr(dot).replaceAll(/\./gim, "");
    }
    if (input.length === 1) {
        input = input.replaceAll(/[^0-9-]/gim, "");
    }
    input = input.slice(0, 1) + input.slice(1).replaceAll("-", "");
    if (input > 100) { input = 100 }
    if (input < 0) { input = 0 }

    return input;
}

const CmykForm = (number, transformTo, onHideLab2) => {
    const { t } = useTranslation();
    const methodchoice = [
        { value: "Bradford", label: "Bradford, linear, compliant with ICC.1:2022 version 4.4, Annex E" },
        { value: "BradfordNonLinear", label: "Bradford, nonlinear" },
        { value: "Von Kries", label: "Von Kries" },
        { value: "XYZ Scaling", label: "XYZ Scaling" },
        { value: "ciecat94", label: "CIECAT94" },
        { value: "Xcmccat97", label: "CMCCAT97" },
        { value: "Xcmccat2000", label: "CMCCAT2000" },
        { value: "Xcat02", label: "CAT02" },
        { value: "rlab", label: "RLAB" },
        { value: "wassef", label: "Wassef" },
        { value: "none", label: t('none') },
    ];

    const [selectedFormat, setSelectedFormat] = useState(format[1]);
    const [method, setMethod] = useState(methodchoice[0]);
    const [sourceSpace, setSourceSpace] = useState(SelectOptions.conversionToColorSpace[0]);
    const [whitepoint, setWhitepoint] = useState(SelectOptions.whitepoint[0]);


    const [c, setC] = useState("0");
    const [m, setM] = useState("0");
    const [y, setY] = useState("0");
    const [k, setK] = useState("0");

    const handleClearValues = () => {
        setC("0");
        setM("0");
        setY("0");
        setK("0");
    };
    useEffect(() => {
        if (sourceSpace.label.includes(whitepoint.label)) {
            setMethod(methodchoice.find((method) => method.value === "none"));
        } else {
            setMethod(methodchoice.find((method) => method.value === "Bradford"));
        }
    }, [sourceSpace, whitepoint]);

    const handleInputChange = (inputHandler) => (setValue) => (e) => {
        const inputValue = inputHandler(e.target.value);
        setValue(inputValue);
    };

    const handleInputBlur = (inputHandler) => (setValue) => (e) => {
        const inputValue = e.target.value.trim() === "" ? "0" : e.target.value;
        const normalizedValue = inputHandler(inputValue, selectedFormat, sourceSpace.value);
        setValue(normalizedValue);
    };

    const handleDeleteInput = () => {
        handleClearValues();
        onHideLab2();
    };

    const [showRlabWarning, setShowRlabWarning] = useState(false);
    const [showWassefWarning, setShowWassefWarning] = useState(false);
    useEffect(() => {
        if (method.value === "rlab") {
            setShowRlabWarning(true);
            setShowWassefWarning(false);
        } else if (method.value === "wassef") {
            setShowRlabWarning(false);
            setShowWassefWarning(true);
        } else {
            setShowRlabWarning(false);
            setShowWassefWarning(false);
        }
    }, [method]);

    const colorTransformationInfo = {
        adaptationMethod: "Bradford" ,
        sourceColorWhite: "D50 1931",
        destinationColorWhite: "D65 1931",
        destinationColorSpace: 'sRGB',
    }

    const LAB = cmyk2lab([c, m, y, k])

    const input = (
        <div className="input">
            <div className="color">
                <p className="colorText">
                    {t('color')} {number} -
                    <p className="switch-value"> CMYK </p>
                    {t('to')}
                    <p className="switch-value"> {transformTo === "rgb" ? "RGB" : "L*a*b*"} </p>
                </p>
                {number === 2 && (<button
                    type="button"
                    onClick={handleDeleteInput}
                    className="switch-button-delete"
                >
                    <img src={deleteIcon} className="button-icon" alt="button" />
                    {t('delete')}
                </button>)}
            </div>

            <form>
                <div className="lab-form-input-container">
                    <label>
                        C:
                        <input
                            type="text"
                            value={c}
                            onChange={(e) => setC(normalizeInput(e.target.value))}
                            onFocus={(e) => e.target.select()}
                            onBlur={handleInputBlur(setC)}
                            className="lab-input"
                        />
                    </label>
                    <label>
                        M:
                        <input
                            type="text"
                            value={m}
                            onChange={(e) => setM(normalizeInput(e.target.value))}
                            onFocus={(e) => e.target.select()}
                            onBlur={handleInputBlur(setM)}
                            className="lab-input"
                        />
                    </label>
                    <label>
                        Y:
                        <input
                            type="text"
                            value={y}
                            onChange={(e) => setY(normalizeInput(e.target.value))}
                            onFocus={(e) => e.target.select()}
                            onBlur={handleInputBlur(setY)}
                            className="lab-input"
                        />
                    </label>
                    <label>
                        K:
                        <input
                            type="text"
                            value={k}
                            onChange={(e) => setK(normalizeInput(e.target.value))}
                            onFocus={(e) => e.target.select()}
                            onBlur={handleInputBlur(setK)}
                            className="lab-input"
                        />
                    </label>
                    <button
                        type="button"
                        onClick={handleClearValues}
                        className="clear-button"
                    >
                        <img src={resetIcon} className="button-icon" alt="button" />
                        {t("clear")}
                    </button>
                </div>
            </form>
        </div>
    );

    return {
        input,
        data: {
            c, m, y, k,
            lab: { l: LAB[0], a: LAB[1], b: LAB[2] }
        },
        colorTransformation: colorTransformationInfo
    };
};

export default CmykForm;

const format = [
    { value: "01", label: "0-1 range" },
    { value: "8-bit", label: "8-bit" },
    { value: "10-bit", label: "10-bit" },
    { value: "15+1-bit", label: "15+1-bit" },
    { value: "16-bit", label: "16-bit" },
    { value: "32-bit", label: "32-bit" },
];

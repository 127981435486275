import React from "react";
import Select, { components } from "react-select";
import { CustomDropdownArrow } from "./SelectArrow";


const withDefaultSelectProps = (WrappedSelect) => {
  return class WithDefaultSelectProps extends React.Component {
    
    static defaultProps = {
      options: [],
      isSearchable: true,
    };
    
    render() {
      const mergedProps = {
        ...WithDefaultSelectProps.defaultProps,
        ...this.props,
      };

      const mergedClassNames = {
        option: (state) => (state.isSelected ? "option-selected" : "option"),
      };

      const mergedStyles = {
        control: (styles, { isFocused }) => ({
          ...styles,
          ":hover": { borderColor: colors.primary, borderShadow: "none" },
          boxShadow: "0",
          borderRadius: "0",
          borderWidth: "1px",
          borderColor: isFocused ? colors.primary : colors.inputBorder,
          borderShadow: "none",
          color: "black",
          cursor: "pointer"
        }),
        container: (styles) => ({
          ...styles,
          width: "100%", 
          maxWidth: "600px",
        }),
        option: (styles, { data, isDisabled, isFocused, isSelected }) => {
          return {
            ...styles,
            ":hover": {
              backgroundColor: `${colors.primary}!important`,
              color: "white!important",
              cursor: "pointer"
            },
            backgroundColor: isSelected ? colors.primary : "white",
          };
        },
        menu: (styles, { data, isDisabled, isFocused, isSelected }) => {
          return {
            ...styles,
            borderRadius: "0",
            marginTop: "0",
            marginBottom: "0",
            paddingTop: "0",
          };
        },
        dropdownIndicator: (
          styles,
          { data, isDisabled, isFocused, isSelected }
        ) => {
          return {
            ...styles,
            color: colors.dark,
            ":hover": { color: colors.dark },
          };
        },
        indicatorSeparator: (
          styles,
          { data, isDisabled, isFocused, isSelected }
        ) => {
          return {
            ...styles,
            display: "none",
          };
        },
        singleValue: (styles) => {
          return {
            ...styles,
            color: "black",
          };
        },
        menuList: (styles) => {
          return {
            ...styles,
            padding: "0",
            color: "black",
            ":hover .option-selected": {
              backgroundColor: "white",
              color: "black",
            },
          };
        },
      };

      return (
        <WrappedSelect
          {...mergedProps}
          components={{ DropdownIndicator: CustomDropdownArrow }}
          styles={mergedStyles}
          classNames={mergedClassNames}
        />
      );
    }
  };
};

const LabSelectForm = withDefaultSelectProps(Select);

export default LabSelectForm;



 const colors = {
  primary: "#00566e",
  secondary: "#3c3c3c",
  success: "#147a11",
  info: "#0084e3",
  warning: "#e1bd00",
  danger: "#980107",
  light: "#f8f9fa",
  dark: "#2c2c2c",
  disabled: "#989898",
  inputBorder: "#E5E5E5",
};

 const fonts = {
  oxygen: "Oxygen",
  barlow: "Barlow",
};

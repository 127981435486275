import React, { useRef, useState } from "react";
import { useTranslation } from 'react-i18next';
import DeltaEResults from "../DeltaEResults";
import XyztoLab from "../math/XyzToLab";
import "../../styles/App.css";
import { rgbAlert, deltaEAlert } from "../Alert";
import RgbToHex from "../math/RgbToHex";
import RgbForm from "../inputForms/RgbForm";
import ChromaticAdaptation from "../math/ChromaticAdaptation";
import { ColorInfo2 } from "../math/ColorInfo2";
import PlusButton from "../PlusButton";

const RgbToLab = () => {
    const { t } = useTranslation();
    const [showLab2, setShowLab2] = useState(false);
    const [buttonVisible, setButtonVisible] = useState(true);

    const handleShowLab2 = () => {
        setShowLab2(true);
        setButtonVisible(false);
    };
    const handleHideLab2 = () => {
        setShowLab2(false);
        setButtonVisible(true);
    };

    const { input: input1, data: rgb1, colorTransformation: colorTransformation1 } = RgbForm(1, "lab");
    const { input: input2, data: rgb2, colorTransformation: colorTransformation2 } = RgbForm(2, "lab", handleHideLab2);

    const adapted1 = ChromaticAdaptation(rgb1.X, rgb1.Y, rgb1.Z, colorTransformation1.sourceColorWhite, colorTransformation1.destinationColorWhite, colorTransformation1.adaptationMethod, colorTransformation1.methodSelect)
    const adapted2 = ChromaticAdaptation(rgb2.X, rgb2.Y, rgb2.Z, colorTransformation2.sourceColorWhite, colorTransformation2.destinationColorWhite, colorTransformation2.adaptationMethod, colorTransformation2.methodSelect)

    const destLab1 = XyztoLab(adapted1.Xd, adapted1.Yd, adapted1.Zd, colorTransformation1.destinationColorWhite)
    const destLab2 = XyztoLab(adapted2.Xd, adapted2.Yd, adapted2.Zd, colorTransformation2.destinationColorWhite)

    const R8bit1 = Math.floor(255 * rgb1.R);
    const G8bit1 = Math.floor(255 * rgb1.G);
    const B8bit1 = Math.floor(255 * rgb1.B);
    const R8bit2 = Math.floor(255 * rgb2.R);
    const G8bit2 = Math.floor(255 * rgb2.G);
    const B8bit2 = Math.floor(255 * rgb2.B);

    const hex1 = RgbToHex(R8bit1, G8bit1, B8bit1);
    const hex2 = RgbToHex(R8bit2, G8bit2, B8bit2);

    const misc1 = ColorInfo2(destLab1.l, destLab1.a, destLab1.b, adapted1.Xd, adapted1.Yd, adapted1.Zd, rgb1.X, rgb1.Y, rgb1.Z, hex1, colorTransformation1)
    const misc2 = ColorInfo2(destLab2.l, destLab2.a, destLab2.b, adapted2.Xd, adapted2.Yd, adapted2.Zd, rgb2.X, rgb2.Y, rgb2.Z, hex2, colorTransformation2)
    
    const data1 = {
        l: destLab1.l, a: destLab1.a, b: destLab1.b,
        R8bit: R8bit1, G8bit: G8bit1, B8bit: B8bit1,
        luvU: misc1.luvU, luvV: misc1.luvV
    };
    const data2 = {
        l: destLab2.l, a: destLab2.a, b: destLab2.b,
        R8bit: R8bit2, G8bit: G8bit2, B8bit: B8bit2,
        luvU: misc2.luvU, luvV: misc2.luvV
    };

    const DeltaEResults1 = DeltaEResults(data1, data2);

    const alertFirstRgb = rgbAlert(rgb1.R, rgb1.G, rgb1.B);
    const alertSecondRgb = rgbAlert(rgb2.R, rgb2.G, rgb2.B);
    const deltaAlert = deltaEAlert(colorTransformation1.sourceColorWhite, colorTransformation2.sourceColorWhite)

    const goTo = (elementRef) => () => {
        if (elementRef && elementRef.current) {
            elementRef.current.scrollIntoView({ behavior: "smooth" });
        }
    };
    const results1Ref = useRef(null);
    const results2Ref = useRef(null);
    const resultsDeltaRef = useRef(null);

    const resultsPreview = (
        <div className="resutlsPreview">
            <div className="resutlsPreview-text-container">
                <div className="resutlsPreview-color-text">
                    {t('color')} 1
                    <br />
                    <b>HEX: {hex1}</b>
                    <br />
                    <button className="goToButton" onClick={goTo(results1Ref)}>{t('goToResults')}</button>
                </div>
                <div className="resutlsPreview-color-text">
                    {t('color')} 2
                    <br />
                    <b>HEX: {hex2}</b>
                    <br />
                    <button className="goToButton" onClick={goTo(results2Ref)}>{t('goToResults')}</button>
                </div>
            </div>
            <div className="resutlsPreview-color-text2">{t('ColorPreview')}</div>
            <div className='resutlsPreview-color-container'>
                <div className='resutlsPreview-color' style={{ backgroundColor: hex1 }} />
                <div className='resutlsPreview-color' style={{ backgroundColor: hex2 }} />
            </div>
            <button className="goToButton" onClick={goTo(resultsDeltaRef)}>{t('checkDiff')}</button>
        </div>
    );


    return (
        <>
            <div className="input-forms-container">
                {input1}
                {showLab2 && resultsPreview}
            </div>
            <div style={{ marginTop: "10px" }}>{alertFirstRgb}</div>
            {buttonVisible && (
                <PlusButton buttonText={t('addRGB')} onClick={handleShowLab2} />
            )}
            {showLab2 && input2}
            <div style={{ marginTop: "10px" }}>{alertSecondRgb}</div>
            <div className="table-title">
                <p className="colorText" ref={results1Ref}>{t('color')} 1 -
                    <b> {t('results')} </b>
                </p>
                    <span className="title-result">L*= {parseFloat(destLab1.l).toFixed(2)}, a*= {Number(destLab1.a).toFixed(2)}, b*= {Number(destLab1.b).toFixed(2)}</span>
            </div>
            <div className="preview-text">{t("ColorPreview")}</div>
            <div style={{ backgroundColor: hex1, maxWidth: 750, height: 70, marginBottom: 25 }} />

            {misc1.table}
            {showLab2 && <>
                <div className="table-title" >
                    <p className="colorText" ref={results2Ref}>{t('color')} 2 -
                        <b> {t('results')} </b>
                    </p>
                        <span className="title-result">L*= {Number(destLab2.l).toFixed(2)}, a*= {Number(destLab2.a).toFixed(2)}, b*= {Number(destLab2.b).toFixed(2)}</span>
                </div>
                <div className="preview-text">{t("ColorPreview")}</div>
                <div style={{ backgroundColor: hex2, maxWidth: 750, height: 70, marginBottom: 25 }} />

                {misc2.table}
            </>}
            {showLab2 && (
                <h3 className="title" ref={resultsDeltaRef}>
                    {t('ColorDiff')}
                </h3>
            )}
            {showLab2 && deltaAlert}
            {showLab2 && <p className="delta-note">{t('deltaNoteAfter')}</p>}
            {showLab2 && DeltaEResults1}
        </>
    );
};

export default RgbToLab;

import fogra39lSet from "./fogra39lSet.json"
import ncs from "./NcsTable.json"

const ERROR_CMYK = [-1, -1, -1, -1];
const ERROR_LAB = [-1, -1, -1];
const ERROR_XYZ = [-1, -1, -1];
const ERROR_NCS = "[no color]";
const ERROR_NCS_INDEX = 0;

const LEN_NCS = 1950;
const LEN_FOGRA = 1617;

function deltaCmyk(cmyk1, cmyk2) {
    const deltaC = cmyk1[0] - cmyk2[0];
    const deltaM = cmyk1[1] - cmyk2[1];
    const deltaY = cmyk1[2] - cmyk2[2];
    const deltaK = cmyk1[3] - cmyk2[3];
    return Math.sqrt(deltaC ** 2 + deltaM ** 2 + deltaY ** 2 + deltaK ** 2);
}

function deltaLab76(lab1, lab2) {
    const deltaL = lab1[0] - lab2[0];
    const deltaA = lab1[1] - lab2[1];
    const deltaB = lab1[2] - lab2[2];
    return Math.sqrt(deltaL ** 2 + deltaA ** 2 + deltaB ** 2);
}

function hPrime(b1, b2, a1p, a2p) {
    let h1p = Math.atan2(b1, a1p) >= 0 
        ? Math.atan2(b1, a1p) 
        : Math.atan2(b1, a1p) + 2 * Math.PI;
    
    let h2p = Math.atan2(b2, a2p) >= 0 
        ? Math.atan2(b2, a2p) 
        : Math.atan2(b2, a2p) + 2 * Math.PI;
    
    return Math.abs(h1p - h2p) > Math.PI 
        ? (h1p + h2p + 2 * Math.PI) / 2 
        : (h1p + h2p) / 2;
}

function T(b1, b2, a1p, a2p) {
    const hp = hPrime(b1, b2, a1p, a2p);
    return 1 - 0.17 * Math.cos(hp - Math.PI/6) +
               0.24 * Math.cos(2 * hp) +
               0.32 * Math.cos(3 * hp + Math.PI/30) -
               0.20 * Math.cos(4 * hp - 63 * Math.PI/180);
}

function deltaLab2000(lab1, lab2, kL = 1) {
    // kL - 1 for graphics, 2 for textiles
    const [l1, a1, b1] = lab1;
    const [l2, a2, b2] = lab2;
    
    const kC = 1;
    const kH = 1;
    const lp = (l1 + l2) / 2;
    
    const cp1 = Math.sqrt(a1 ** 2 + b1 ** 2);
    const cp2 = Math.sqrt(a2 ** 2 + b2 ** 2);
    const cp = (cp1 + cp2) / 2;
    
    const g = 0.5 * (1 - Math.sqrt(cp ** 7 / (cp ** 7 + 25 ** 7)));
    const a1p = a1 * (1 + g);
    const a2p = a2 * (1 + g);
    
    const c1p = Math.sqrt(a1p ** 2 + b1 ** 2);
    const c2p = Math.sqrt(a2p ** 2 + b2 ** 2);
    const cp_ = (c1p + c2p) / 2;
    
    let h1p = Math.atan2(b1, a1p) >= 0 
        ? Math.atan2(b1, a1p) 
        : Math.atan2(b1, a1p) + 2 * Math.PI;
    
    let h2p = Math.atan2(b2, a2p) >= 0 
        ? Math.atan2(b2, a2p) 
        : Math.atan2(b2, a2p) + 2 * Math.PI;
    
    const dlP = l2 - l1;
    const dcP = c2p - c1p;
    
    let dhPrime;
    if (c1p * c2p === 0) {
        dhPrime = 0;
    } else if (Math.abs(h2p - h1p) <= Math.PI) {
        dhPrime = h2p - h1p;
    } else if (h2p - h1p > Math.PI) {
        dhPrime = h2p - h1p - 2 * Math.PI;
    } else {
        dhPrime = h2p - h1p + 2 * Math.PI;
    }
    
    const dhp = 2 * Math.sqrt(c1p * c2p) * Math.sin(dhPrime / 2);
    const sl = 1 + (0.015 * (lp - 50) ** 2) / Math.sqrt(20 + (lp - 50) ** 2);
    const sc = 1 + 0.045 * cp_;
    const sh = 1 + 0.015 * cp_ * T(b1, b2, a1p, a2p);
    const rt = -Math.sin(2 * dhPrime) * (2 * Math.sqrt(cp_ ** 7 / (cp_ ** 7 + 25 ** 7)));
    
    return Math.sqrt(
        (dlP / (kL * sl)) ** 2 +
        (dcP / (kC * sc)) ** 2 +
        (dhp / (kH * sh)) ** 2 +
        rt * (dcP / (kC * sc)) * (dhp / (kH * sh))
    );
}

function matchCmyk2fogra(inputCmyk) {
    const deltas = fogra39lSet.map((row, index) => ({
        delta: deltaCmyk([row[1], row[2], row[3], row[4]], inputCmyk),
        index
    }));

    const sortedDeltas = deltas.sort((a, b) => a.delta - b.delta);
    const fograIndices = sortedDeltas.slice(0, 6).map(d => d.index);

    if (sortedDeltas[0].delta < 1.0) {
        return {
            bestWeights: [1, 0, 0, 0, 0, 0],
            fograIndices
        };
    }

    const ranks = fograIndices.map(index => fogra39lSet[index].slice(1, 5));
    let bestWeights = [0, 0, 0, 0, 0, 0];
    let bestDelta = 200;
    const wStep = 0.25;

    // Coarse search
    for (let w1 = 0; w1 <= 1.0; w1 += wStep) {
        for (let w2 = 0; w2 <= 1.0; w2 += wStep) {
            for (let w3 = 0; w3 <= 1.0; w3 += wStep) {
                for (let w4 = 0; w4 <= 1.0; w4 += wStep) {
                    for (let w5 = 0; w5 <= 1.0; w5 += wStep) {
                        for (let w6 = 0; w6 <= 1.0; w6 += wStep) {
                            const weights = [w1, w2, w3, w4, w5, w6];
                            const weightSum = weights.reduce((a, b) => a + b, 0);
                            if (weightSum === 0) continue;

                            const meanCmyk = ranks.reduce((acc, rank, i) => 
                                acc.map((v, j) => v + rank[j] * weights[i]), [0, 0, 0, 0])
                                .map(v => v / weightSum);

                            const currentDelta = deltaCmyk(meanCmyk, inputCmyk);
                            if (currentDelta < bestDelta) {
                                bestDelta = currentDelta;
                                bestWeights = weights;
                            }
                        }
                    }
                }
            }
        }
    }

    // Fine search
    const wStepFine = wStep / 4;
    const searchAround = (w, base) => {
        let result = base;
        for (let delta = -wStep/2; delta <= wStep/2; delta += wStepFine) {
            result = Math.max(0, Math.min(1, w + delta));
        }
        return result;
    };

    bestWeights = bestWeights.map((w, i) => searchAround(w, bestWeights[i]));

    return { bestWeights, fograIndices };
}

function cmyk2lab(inputCmyk) {
    if (inputCmyk.every((v, i) => v === ERROR_CMYK[i])) {
        return ERROR_LAB;
    }

    const { bestWeights, fograIndices } = matchCmyk2fogra(inputCmyk);
    const weightSum = bestWeights.reduce((a, b) => a + b, 0);

    return fograIndices.reduce((acc, index, i) => {
        const lab = fogra39lSet[index].slice(8, 11);
        return acc.map((v, j) => v + lab[j] * bestWeights[i]);
    }, [0, 0, 0]).map(v => v / weightSum);
}

function processNcsName(inputString) {
    return inputString.toLowerCase()
        .replace(/s/g, '')
        .replace(/\s/g, '')
        .replace(/-/g, '');
}

function ncs2cmyk(inputName) {
    if (inputName === ERROR_NCS || inputName === '') {
        return ERROR_CMYK;
    }

    const procInputName = processNcsName(inputName);
    const match = ncs.find(row => processNcsName(row[1]) === procInputName);
    
    return match ? [match[2], match[3], match[4], match[5]] : ERROR_CMYK;
}



function lab2cmyk(inputLab) {
    if (inputLab.every((v, i) => v === ERROR_LAB[i])) {
        return ERROR_CMYK;
    }

    if (inputLab[0] <= 1) {
        return [0, 0, 0, 100];
    }

    if (inputLab[0] < 5) {
        let bestDelta = 1000;
        let bestFograIndex = 0;

        fogra39lSet.forEach((row, i) => {
            const fograLab = row.slice(8, 11);
            const deltaL = (fograLab[0] - inputLab[0]) * 3;
            const deltaA = fograLab[1] - inputLab[1];
            const deltaB = fograLab[2] - inputLab[2];
            const currentDelta = Math.sqrt(deltaL ** 2 + deltaA ** 2 + deltaB ** 2);
            
            if (currentDelta < bestDelta) {
                bestDelta = currentDelta;
                bestFograIndex = i;
            }
        });

        return fogra39lSet[bestFograIndex].slice(1, 5);
    }

    let bestDelta = 200;
    let bestFograIndex = 0;

    fogra39lSet.forEach((row, i) => {
        const fograLab = row.slice(8, 11);
        const currentDelta = deltaLab2000(fograLab, inputLab);
        if (currentDelta < bestDelta) {
            bestDelta = currentDelta;
            bestFograIndex = i;
        }
    });

    return fogra39lSet[bestFograIndex].slice(1, 5);
}

function lab2ncs(inputLab) {
    if (inputLab.every((v, i) => v === ERROR_LAB[i])) {
        return ERROR_NCS;
    }

    let bestDelta = 200;
    let bestNcsIndex = 19;

    ncs.forEach((row, i) => {
        const ncsLab = [row[9], row[10], row[11]];
        const currentDelta = deltaLab2000(ncsLab, inputLab);
        if (currentDelta < bestDelta) {
            bestDelta = currentDelta;
            bestNcsIndex = i;
        }
    });

    return ncs[bestNcsIndex][1];
}

function cmyk2xyz(inputCmyk) {
    if (inputCmyk.every((v, i) => v === ERROR_CMYK[i])) {
        return ERROR_XYZ;
    }

    const { bestWeights, fograIndices } = matchCmyk2fogra(inputCmyk);
    const weightSum = bestWeights.reduce((a, b) => a + b, 0);

    return fograIndices.reduce((acc, index, i) => {
        const xyz = fogra39lSet[index].slice(5, 8);
        return acc.map((v, j) => v + xyz[j] * bestWeights[i]);
    }, [0, 0, 0]).map(v => v / weightSum);
}

function ncs2xyz(inputName) {
    if (inputName === ERROR_NCS || inputName === '') {
        return ERROR_XYZ;
    }

    const cmyk = ncs2cmyk(inputName);
    return cmyk2xyz(cmyk);
}

function getNcsName(inputIndex) {
    if (inputIndex === ERROR_NCS_INDEX) {
        return ERROR_NCS;
    }
    if (inputIndex < 0 || inputIndex >= ncs.length) {
        return ERROR_NCS;
    }
    return ncs[inputIndex][1];
}

export {
    cmyk2lab,
    lab2cmyk,
    ncs2xyz,
    ncs2cmyk,
    lab2ncs,
    getNcsName,
    cmyk2xyz
};
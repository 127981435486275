import React, { useRef, useState } from "react";
import { useTranslation } from 'react-i18next';
import DeltaEResults from "../DeltaEResults";
import LabForm from "../inputForms/LabForm";
import RgbResults from "../RgbResults";
import "../../styles/App.css";
import { rgbAlert, deltaEAlert } from "../Alert";
import LabToXyz from "../math/LabToXyz";
import { ColorInfo } from "../math/ColorInfo";
import PlusButton from "../PlusButton";

const LabToRgb = () => {
    const { t } = useTranslation();
    const [showLab2, setShowLab2] = useState(false);
    const [buttonVisible, setButtonVisible] = useState(true);

    const handleShowLab2 = () => {
        setShowLab2(true);
        setButtonVisible(false);
    };

    const handleHideLab2 = () => {
        setShowLab2(false);
        setButtonVisible(true);
    };
    const { input: input1, data: lab1, colorTransformation: colorTransformation1 } = LabForm(1, "rgb" );
    const { input: input2, data: lab2, colorTransformation: colorTransformation2 } = LabForm(2, "rgb", handleHideLab2);

    const Xyz1 = LabToXyz(lab1.l, lab1.a, lab1.b, colorTransformation1.sourceColorWhite)
    const Xyz2 = LabToXyz(lab2.l, lab2.a, lab2.b, colorTransformation2.sourceColorWhite)
    const RgbResults1 = RgbResults(Xyz1.X, Xyz1.Y, Xyz1.Z, colorTransformation1);
    const RgbResults2 = RgbResults(Xyz2.X, Xyz2.Y, Xyz2.Z, colorTransformation2);
    const deltaAlert = deltaEAlert(colorTransformation1.sourceColorWhite, colorTransformation2.sourceColorWhite)
    const alertFirstRgb = rgbAlert(RgbResults1.rgbAlertData.ExtendedR, RgbResults1.rgbAlertData.ExtendedG, RgbResults1.rgbAlertData.ExtendedB);
    const alertSecondRgb = rgbAlert(RgbResults2.rgbAlertData.ExtendedR, RgbResults2.rgbAlertData.ExtendedG, RgbResults2.rgbAlertData.ExtendedB);

    const misc1 = ColorInfo(lab1.l, lab1.a, lab1.b, Xyz1.X, Xyz1.Y, Xyz1.Z, RgbResults1.data.sX, RgbResults1.data.sY, RgbResults1.data.sZ, RgbResults1.data.hex, colorTransformation1, RgbResults1.data.destinationLab)
    const misc2 = ColorInfo(lab2.l, lab2.a, lab2.b, Xyz2.X, Xyz2.Y, Xyz2.Z, RgbResults2.data.sX, RgbResults2.data.sY, RgbResults2.data.sZ, RgbResults2.data.hex, colorTransformation1, RgbResults2.data.destinationLab)

    const data1 = {
        l: lab1.l, a: lab1.a, b: lab1.b,
        R8bit: RgbResults1.data.R8bit, G8bit: RgbResults1.data.G8bit, B8bit: RgbResults1.data.B8bit,
        luvU: misc1.luvU, luvV: misc1.luvV
    };
    const data2 = {
        l: lab2.l, a: lab2.a, b: lab2.b,
        R8bit: RgbResults2.data.R8bit, G8bit: RgbResults2.data.G8bit, B8bit: RgbResults2.data.B8bit,
        luvU: misc2.luvU, luvV: misc2.luvV
    };

    const DeltaEResults1 = DeltaEResults(data1, data2);

    const goTo = (elementRef) => () => {
        if (elementRef && elementRef.current) {
            elementRef.current.scrollIntoView({ behavior: "smooth" });
        }
    };
    const results1Ref = useRef(null);
    const results2Ref = useRef(null);
    const resultsDeltaRef = useRef(null);

    const resultsPreview = (
        <div className="resutlsPreview">
            <div className="resutlsPreview-text-container">
                <div className="resutlsPreview-color-text">
                    {t('color')} 1
                    <br />
                    <b>HEX: {RgbResults1.data.hex}</b>
                    <br />
                    <button className="goToButton" onClick={goTo(results1Ref)}>{t('goToResults')}</button>
                </div>
                <div className="resutlsPreview-color-text">
                    {t('color')} 2
                    <br />
                    <b>HEX: {RgbResults2.data.hex}</b>
                    <br />
                    <button className="goToButton" onClick={goTo(results2Ref)}>{t('goToResults')}</button>
                </div>
            </div>
            <div className="resutlsPreview-color-text2">{t('ColorPreview')}</div>
            <div className='resutlsPreview-color-container'>
                <div className='resutlsPreview-color' style={{ backgroundColor: RgbResults1.data.hex }} />
                <div className='resutlsPreview-color' style={{ backgroundColor: RgbResults2.data.hex }} />
            </div>
            <button className="goToButton" onClick={goTo(resultsDeltaRef)}>{t('checkDiff')}</button>
        </div>
    );

    return (
        <>
            <div className="input-forms-container">
                {input1}
                {showLab2 && resultsPreview}
            </div>
            <div style={{ marginTop: "10px" }}>{alertFirstRgb}</div>
            {buttonVisible && (
                <PlusButton buttonText={t('addLAB')} onClick={handleShowLab2} />
            )}
            {showLab2 && input2}
            <div style={{ marginTop: "10px" }}>{alertSecondRgb}</div>
            <div className="table-title">
                <p className="colorText" ref={results1Ref}>{t('color')} 1 -
                    <b> {t('results')} </b>
                </p>
            </div>
            {RgbResults1.table}
            {misc1.table}
            {showLab2 &&
                <div className="table-title">
                    <p className="colorText" ref={results1Ref}>{t('color')} 2 -
                        <b> {t('results')} </b>
                    </p>
                </div>}
            {showLab2 && RgbResults2.table}
            {showLab2 && misc2.table}
            {showLab2 && (
                <h3 className="title" ref={resultsDeltaRef}>
                    {t('ColorDiff')}
                </h3>
            )}
            {showLab2 && deltaAlert}
            {showLab2 && <p className="delta-note">{t('deltaNoteBefore')}</p>}
            {showLab2 && DeltaEResults1}
        </>
    );
};

export default LabToRgb;

import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from 'react-i18next';
import { useNavigate } from "react-router-dom";
import '../../styles/Language.css';
import diag_logo from "./logos/diag_logo.svg";
import diag_logo_dark from "./logos/diag_logo_dark.svg";
import './welcome-page.css';
import ThemeToggle from "../ThemeToggle";
import { useTheme } from "../ThemeContext";


function Header() {  
  const { theme } = useTheme();

  return (
    <header className="header-container">
      <div className="header-flex">
        <div className="header-logotypes">
          <a href="#" aria-label="diagnosis-logo">
            <img 
              src={diag_logo} 
              alt="diagnosis" 
              className={`diagnosis ${theme === 'dark' ? 'hidden' : ''}`} 
            />
            <img 
              src={diag_logo_dark} 
              alt="diagnosis" 
              className={`diagnosis ${theme === 'light' ? 'hidden' : ''}`} 
            />
          </a>
        </div>
        <Language />
        <ThemeToggle />
      </div>
    </header>
  );
}

export default Header;



const Language = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  const handleLanguageChange = (language) => {
    i18n.changeLanguage(language);
    localStorage.setItem('selectedLanguage', language);

    // Update URL based on language
    const newPath = language === 'pl' ? '/' : '/en';
    navigate(newPath);
  };

  return (
    <div className="lang">
      <button
        className={`lang-button ${i18n.language === 'pl' ? 'active' : ''}`}
        onClick={() => handleLanguageChange('pl')}>
        PL
      </button>
      <button
        className={`lang-button ${i18n.language === 'en' ? 'active' : ''}`}
        onClick={() => handleLanguageChange('en')}>
        EN
      </button>
    </div>
  );
};

